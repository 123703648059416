/*
@File: molbio Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - Featured Boxes Area CSS
** - About Area CSS
** - Services Area CSS
** - Services Details Area CSS
** - Why Choose Us Area CSS
** - Overview Area CSS
** - Fun Facts Area CSS
** - Projects Area CSS
** - Projects Details Area CSS
** - Features Area CSS
** - Team Area CSS
** - Solution Area CSS
** - Feedback Area CSS
** - Pricing Area CSS
** - Partner Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Subscribe Area CSS
** - Page Title Area CSS
** - Pagination Area CSS
** - Sidebar Area CSS
** - 404 Error Area CSS
** - Shop Area CSS
** - Product Details Area CSS
** - Cart Area CSS
** - Checkout Area CSS
** - FAQ Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Go Top CSS
*/

/*================================================
Default CSS
=================================================*/

$font-family: 'Overpass', sans-serif;
$main-color: #0000ff;
$optional-color: #fa7070;
$white-color: #ffffff;
$black-color: #212529;
$paragraph-color: #737b9a;
$font-size: 16px;
$transition: .5s;
$gray-color: #f9f9fc;

body {
    padding: 0;
    margin: 0;
    font: {
        size: $font-size;
        family: $font-family;
    }
}
img {
    max-width: 100%;
    height: auto;
}
button {
    outline: 0 !important;
    &:focus {
        outline: 0 !important;
    }
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }
}
.pt-100 {
    padding-top: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.ptb-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}
a {
    text-decoration: none;
    transition: $transition;
    outline: 0 !important;
    color: $black-color;
    display: inline-block;

    &:hover {
        text-decoration: none;
        color: $main-color;
    }
}
h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}
p {
    font-size: $font-size;
    line-height: 1.8;
    color: $paragraph-color;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }
}
.bg-F4F7FC {
    background-color: #F4F7FC;
}
.bg-fafafa {
    background-color: #fafafa;
}
.main-color {
    color: $main-color;
}
.plr-5 {
    padding: 0 5px;
}
.blog-ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px !important;
}
.mr-3 {
    margin-right: 10px;
}
.mr-4 {
    margin-right: 15px;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: end !important;
}
/* default-btn */
.default-btn {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $white-color;
    text-transform: uppercase;
    background-color: $main-color;
    transition: $transition;
    border-radius: 5px;
    font: {
        weight: 600;
        size: 15px;
    }
    padding: {
        left: 35px;
        right: 35px;
        top: 17px;
        bottom: 13px;
    }
    span {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: $optional-color;
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
    }
    &:hover, &:focus {
        color: $white-color;

        span {
            width: 225%;
            height: 562.5px;
        }
    }
}
/* Section title */
.section-title {
    text-align: center;
    max-width: 600px;
    margin: {
        left: auto;
        right: auto;
        bottom: 55px;
    }
    .sub-title {
        color: $main-color;
        display: block;
        text-transform: uppercase;
        margin-bottom: 8px;
        font: {
            size: 15px;
            weight: 600;
        }
    }
    h2 {
        max-width: 560px;
        text-transform: capitalize;
        font: {
            weight: 800;
            size: 40px;
        }
        margin: {
            left: auto;
            right: auto;
            bottom: 0;
        }
    }
    p {
        margin: {
            top: 12px;
            bottom: 0;
        }
    }
}
/* Form control */
.form-control {
    height: 50px;
    border: 1px solid #eeeeee;
    background-color: #fff;
    border-radius: 0;
    transition: $transition;
    color: $black-color;
    line-height: initial;
    padding-left: 13px;
    font: {
        size: 14.5px;
    }
    &:hover, &:focus {
        box-shadow: unset;
    }
    &::placeholder {
        color: #999999;
    }
    &:focus {
        box-shadow: unset;
        border-color: $main-color;
    }
}
textarea.form-control {
    padding-top: 13px;
}
.form-select {
    height: 50px;
    border: 1px solid #eeeeee;
    background-color: #fff;
    border-radius: 0;
    transition: $transition;
    color: $black-color;
    line-height: initial;
    padding-left: 13px;
    font: {
        size: 14.5px;
    }
    &:hover, &:focus {
        box-shadow: unset;
    }
    &::placeholder {
        color: #999999;
    }
    &:focus {
        box-shadow: unset;
        border-color: $main-color;
    }
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: transparent;
    top: 0;
    left: 0;

    .loader {
        position: absolute;
        top: 43%;
        left: 0;
        right: 0;
        transform: translateY(-43%);
        text-align: center;
        margin: 0 auto;
        width: 50px;
        height: 50px;
        transition: $transition;

        .box {
            width: 100%;
            height: 100%;
            background: $white-color;
            animation: animate .5s linear infinite;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 3px;
        }
        .shadow { 
            width: 100%;
            height: 5px;
            background: #000000;
            opacity: 0.1;
            position: absolute;
            top: 59px;
            left: 0;
            border-radius: 50%;
            animation: shadow .5s linear infinite;
        }
    }
    &::before, &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 60%;
        height: 100%;
        z-index: -1;
        background: $main-color;
        transition: .9s;
    }
    &::after {
        left: auto;
        right: 0;
    }
    &.preloader-deactivate {
        visibility: hidden;

        &::after, &::before {
            width: 0;
        }
        .loader {
            opacity: 0;
            visibility: hidden;
        }
    }
}
@keyframes loader {
    0% {
        left: -100px
    }
    100% {
        left: 110%;
    }
}
@keyframes animate {
    17% {
        border-bottom-right-radius: 3px;
    }
    25% {
        transform: translateY(9px) rotate(22.5deg);
    }
    50% {
        transform: translateY(18px) scale(1,.9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }
    75% {
        transform: translateY(9px) rotate(67.5deg);
    }
    100% {
        transform: translateY(0) rotate(90deg);
    }
}
@keyframes shadow {
    50% {
        transform: scale(1.2,1);
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.main-nav {
    background-color: transparent;
    padding: {
        top: 15px;
        bottom: 15px;
        right: 0;
        left: 0;
    }
    .navbar {
        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin: {
                left: auto;
            }
            .nav-item {
                position: relative;
                padding: {
                    top: 15px;
                    bottom: 15px;
                    left: 0;
                    right: 0;
                }
                a {
                    font: {
                        size: $font-size;
                        weight: 500;
                    }
                    color: #5b5b98;
                    text-transform: capitalize;
                    padding: {
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                    margin: {
                        left: 15px;
                        right: 15px;
                    }
                    &:hover, &:focus, &.active {
                        color: $main-color;
                    }
                    i {
                        font-size: 10px;
                        position: relative;
                        top: -1px;
                        margin-left: 1px;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover, &:focus, &.active {
                    a {
                        color: $main-color;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: $white-color;
                    position: absolute;
                    border: none;
                    top: 80px;
                    left: 0;
                    width: 230px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    transition: all 0.3s ease-in-out;
                    border-top: 3px solid $main-color;
                    padding: {
                        top: 20px;
                        left: 25px;
                        right: 25px;
                        bottom: 20px;
                    }
                    li {
                        padding: 0;

                        a {
                            text-transform: capitalize;
                            padding: 8px 0;
                            margin: 0;
                            color: #696997;
                            font: {
                                size: 15px;
                            }
                            &:hover, &:focus, &.active {
                                color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            left: -245px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;
                            
                            li {
                                a {
                                    color: #696997;
                                    
                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    left: -245px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;
        
                                    li {
                                        a {
                                            color: #696997;
                                            text-transform: capitalize;
        
                                            &:hover, &:focus, &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -245px;
                                            top: 0;
                                            opacity: 0;
                                            visibility: hidden;
                
                                            li {
                                                a {
                                                    color: #696997;
                                                    text-transform: capitalize;
                
                                                    &:hover, &:focus, &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    left: 195px;
                                                    top: 0;
                                                    opacity: 0;
                                                    visibility: hidden;
                        
                                                    li {
                                                        a {
                                                            color: #696997;
                                                            text-transform: capitalize;
                        
                                                            &:hover, &:focus, &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            left: 195px;
                                                            top: 0;
                                                            opacity: 0;
                                                            visibility: hidden;
                                
                                                            li {
                                                                a {
                                                                    color: #696997;
                                                                    text-transform: capitalize;
                                
                                                                    &:hover, &:focus, &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    left: 195px;
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    visibility: hidden;
                                        
                                                                    li {
                                                                        a {
                                                                            color: #696997;
                                                                            text-transform: capitalize;
                                        
                                                                            &:hover, &:focus, &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        top: -15px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                top: -15px;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        top: -15px;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                top: -15px;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        top: -15px;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: -15px;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
            }
        }
        .others-options {
            margin-left: 30px;
            .cart-items {
                position: relative;
                display: inline-block;
                margin-right: 30px;
                
                a {
                    color: $paragraph-color;
                    &:hover {
                        color: $main-color;
                    }
                }

                span {
                    position: absolute;
                    background-color: #ff3547;
                    color: #fff;
                    font-size: 10px;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    line-height: 22px;
                    border-radius: 50%;
                    top: -10px;
                    right: -16px;
                }
            }
            .option-item {
                color: #5b5b98;
                display: inline-block;
                position: relative;
                line-height: 1;

                .search-btn {
                    cursor: pointer;
                    transition: $transition;
                    color: #5b5b98;
                    font: {
                        size: 20px;
                        weight: 600;
                    }
                    &:hover {
                        color: $main-color;
                    }
                }
                .close-btn {
                    cursor: pointer;
                    display: none;
                    transition: $transition;
                    width: 20px;
                    color: #5b5b98;
                    font-size: 18px;
                    padding: 0 5px;
    
                    &.active {
                        display: block;
                    }
                    &:hover {
                        color: $main-color;
                    }
                }
            }
            .burger-menu {
                width: 35px;
                height: auto;
                background-color: transparent;
                margin-left: 20px;
                cursor: pointer;
                display: inline-block;
                position: relative;
                top: 1px;
        
                span {
                    display: block;
                    width: 35px;
                    height: 3px;
                    background: #5b5b98;
                    transition: $transition;
                    border-radius: 30px;
                    margin: {
                        left: auto;
                        bottom: 4px;
                    }
                    &:nth-child(3) {
                        margin-bottom: 0;
                        width: 28px;
                        margin-left: auto;
                    }
                }
                &:hover {
                    span {
                        background: $black-color;

                        &:nth-child(3) {
                            width: 35px;
                        }
                    }
                }
            }
        }
    }
}
.navbar-area {
    background-color: transparent;
    transition: $transition;
    position: absolute;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;

    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        background: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        transition: $transition;
    }

    .new {
        position: absolute;
        font-size: 8px;
        line-height: 1;
        color: #fff;
        background-color: #dc3545;
        -webkit-box-shadow: rgba(75, 109, 235, 0.57) 0 8px 19.74px -2px;
        box-shadow: rgba(75, 109, 235, 0.57) 0 8px 19.74px -2px;
        z-index: 1;
        pointer-events: none;
        border-radius: 4px;
        padding: 4px 5px 3px;
        margin: -3px 0 0 5px;
    }
}
.search-overlay {
    display: none;

    &.search-popup {
        position: absolute;
        top: 100%;
        width: 300px;
        right: 0;
        background: $white-color;
        z-index: 100;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 25px;

        .search-form {
            position: relative;
            
            .search-input {
                display: block;
                width: 100%;
                height: 45px;
                line-height: initial;
                border: 1px solid #eeeeee;
                color: $black-color;
                outline: 0;
                transition: $transition;
                font-size: 13px;
                padding: 10px 15px;

                &:focus {
                    border-color: $main-color;
                }
            }
            .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 45px;
                background: transparent;
                border: none;
                width: 45px;
                outline: 0;
                color: $paragraph-color;
                transition: $transition;
                padding: 0;

                &:hover, &:focus {
                    color: $main-color;
                }
            }
        }
    }
}
.sidebar-modal {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    overflow: hidden;

    .sidebar-modal-inner {
        position: absolute;
        right: -100%;
        top: 0;
        width: 30%;
        overflow-y: scroll;
        height: 100%;
        background-color: $white-color;
        transition: .7s;
        z-index: 1;
        padding: {
            top: 80px;
            bottom: 40px;
            left: 40px;
            right: 40px;
        }
        .close-btn {
            display: inline-block;
            position: absolute;
            right: 35px;
            top: 20px;
            font-size: 20px;
            transition: $transition;
            color: $black-color;
            opacity: .66;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }
    .sidebar-about-area {
        margin: {
            bottom: 40px;
        }
        .title {
            h2 {
                margin-bottom: 0;
                font: {
                    size: 24px;
                    weight: 700;
                }
            }
            p {
                margin-top: 15px;
                font-size: 15px;
            }
        }
    }
    .sidebar-instagram-feed {
        margin: {
            bottom: 40px;
        }
        h2 {
            margin-bottom: 25px;
            font: {
                size: 24px;
            }
        }
        ul {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            padding-left: 0;
            list-style-type: none;
            margin: {
                left: -5px;
                right: -5px;
                bottom: 0;
                top: -10px;
            }
            li {
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
                padding: {
                    left: 5px;
                    right: 5px;
                    top: 10px;
                }
                a {
                    display: block;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #000000;
                        transition: $transition;
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover {
                        &::before {
                            opacity: .50;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .sidebar-contact-area {
        .contact-info {
            text-align: center;
        
            .contact-info-content {
                h2 {
                    margin-bottom: 0;
                    font: {
                        size: 20px;
                    }
                    a {
                        display: inline-block;
                        color: $main-color;
        
                        &:hover {
                            color: $black-color;
                        }
                        &:not(:first-child) {
                            color: $black-color;
        
                            &:hover {
                                color: $main-color;
                            }
                        }
                    }
                    .or {
                        display: block;
                        color: $paragraph-color;
                        margin: {
                            top: 10px;
                            bottom: 10px;
                        }
                        font: {
                            size: 14px;
                            weight: 600;
                        }
                    }
                }
                .social {
                    padding-left: 0;
                    list-style-type: none;
                    margin: {
                        bottom: 0;
                        top: 20px;
                    }
                    li {
                        display: inline-block;
                        margin: 0 3px;
                        padding-left: 0;

                        a {
                            i {
                                width: 32px;
                                height: 32px;
                                line-height: 32px;
                                border: 1px solid #dadada;
                                border-radius: 50%;
                                color: #aba5a5;
                                display: block;
                                font-size: 12px;
                                transition: .6s;

                                &:hover {
                                    color: $white-color;
                                    border-color: $main-color;
                                    background-color: $main-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.active {
        opacity: 1;
        visibility: visible;
        
        .sidebar-modal-inner {
            right: 0;
        }
    }
}

// Navbar toggler 
.navbar-toggler {
    border: none;
    background: #eee !important;
    padding: 10px;
    border-radius: 0;
    &:focus {
        box-shadow: none;
    }

    .icon-bar {
        width: 28px;
        transition: all 0.3s;
        background: $main-color;
        height: 2px;
        display: block;
    }
    .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
        left: 4px;
        position: relative;
    }
    .middle-bar {
        opacity: 0;
        margin: 6px 0;
    }
    .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
        left: 4px;
        position: relative;
    }
    &.collapsed {
        .top-bar {
            transform: rotate(0);      
            left: 0;
        }
        .middle-bar {
            opacity: 1;
        }
        .bottom-bar {
            transform: rotate(0);
            left: 0;
        }
    }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-color: #f5f5fc;
    padding: {
        top: 190px;
    };
}
.main-banner-content {
    text-align: center;
    max-width: 790px;
    margin: {
        left: auto;
        right: auto;
        bottom: 70px;
    };
    .sub-title {
        color: $main-color;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        font: {
            size: 15px;
            weight: 600;
        }
    }
    h1 {
        line-height: 1.2;
        margin-bottom: 0;
        font: {
            size: 60px;
        }
    }
    p {
        font-size: 17px;
        margin: {
            top: 15px;
            bottom: 0;
        }
    }
    .btn-box {
        margin-top: 25px;
    }
    form {
        position: relative;
        margin: {
            top: 30px;
        }
        .input-newsletter {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: none;
            height: 65px;
            padding-left: 25px;
            border-radius: 70px;
            padding-top: 5px;
            outline: 0;
            color: $black-color;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;

            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            height: 55px;
            padding: 0 30px;
            border-radius: 70px;
            transition: $transition;
            line-height: 60px;
            text-transform: uppercase;
            font: {
                size: 15px;
                weight: 700;
            }
            &:hover {
                background-color: $optional-color;
                color: $white-color;
            }
        }
    }
}
.main-banner-image {
    text-align: center;
}
.banner-section {
    overflow: hidden;
    position: relative;
    background-color: #f5f5fc;
    z-index: 1;
    height: 950px;
}
.banner-content {
    margin-top: -20px;

    .sub-title {
        color: $main-color;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        font: {
            size: 15px;
            weight: 600;
        }
    }
    h1 {
        line-height: 1.2;
        margin-bottom: 0;
        font: {
            size: 59px;
        }
    }
    p {
        font-size: 17px;
        margin: {
            top: 15px;
            bottom: 0;
        }
    }
    form {
        max-width: 580px;
        position: relative;
        margin: {
            top: 30px;
        }
        .input-newsletter {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: none;
            height: 65px;
            padding-left: 25px;
            border-radius: 5px;
            padding-top: 5px;
            outline: 0;
            color: $black-color;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;

            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            height: 55px;
            padding: 0 30px;
            border-radius: 5px;
            transition: $transition;
            line-height: 60px;
            text-transform: uppercase;
            font: {
                size: 15px;
                weight: 700;
            }
            &:hover {
                background-color: $optional-color;
                color: $white-color;
            }
        }
    }
}
.banner-image {
    position: relative;
    text-align: center;

    img {
        &:nth-child(1) {
            position: absolute;
            z-index: 1;
            bottom: -180px;
            left: 65px;
            right: 0;
            margin: 0 auto;
        }
        &:nth-child(2) {
            position: relative;
            left: 35px;
            top: 150px;
            z-index: -1;
        }
    }
}
.cloud-shape {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: auto;
}
.cloud-icon {
    position: absolute;
    right: 15%;
    top: 20%;
    transform: translateY(-20%) translateX(-15%);
    z-index: -1;
}
.cloud-icon1 {
    position: absolute;
    z-index: -1;
    top: 12%;
    right: 36.5%;
    animation: moveLeftBounce 3s linear infinite;
}
.cloud-icon2 {
    position: absolute;
    z-index: -1;
    top: 36%;
    right: 20%;
    animation: moveLeftBounce 5s linear infinite;
}
.cloud-icon3 {
    position: absolute;
    z-index: -1;
    top: 48%;
    right: 40%;
    animation: moveLeftBounce 3s linear infinite;
}
.shape-img1 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    max-width: 220px;
}
.shape-img2 {
    position: absolute;
    left: 5%;
    top: 10%;
    z-index: -1;
    animation-name: rotateMe;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.shape-img3 {
    position: absolute;
    right: 5%;
    bottom: 5%;
    z-index: -1;
    animation-name: rotateMe;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.shape-img4 {
    position: absolute;
    right: 8%;
    top: 8%;
    z-index: -1;
    animation: animationFramesTwo 20s infinite linear;
}
.shape-img5 {
    position: absolute;
    left: 2%;
    bottom: 25%;
    z-index: -1;
    animation: animationFramesOne 20s infinite linear;
}
.shape-img6 {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    z-index: -1;
    bottom: 0;
    animation: rotate3d 4s linear infinite;

    img {
        opacity: .20;
    }
}
.shape-img7 {
    position: absolute;
    left: 10%;
    top: 35%;
    z-index: -1;
    animation: moveBounce 5s linear infinite;

    img {
        opacity: .30;
    }
}
.shape-img8 {
    position: absolute;
    top: 12%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: -1;
    animation: moveLeftBounce 5s linear infinite;

    img {
        opacity: .40;
    }
}
.shape-img9 {
    position: absolute;
    left: 20%;
    top: 15%;
    z-index: -1;
    animation: moveScale 3s linear infinite;

    img {
        opacity: .40;
    }
}
.shape-img10 {
    position: absolute;
    left: 75%;
    z-index: -1;
    top: 40%;
    animation: animationFramesOne 20s infinite linear;
}
@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(-73px, 1px) rotate(-36deg);
    }
    40% {
        transform: translate(-141px, -72px) rotate(-72deg);
    }
    60% {
        transform: translate(-83px, -122px) rotate(-108deg);
    }
    80% {
        transform: translate(40px, -72px) rotate(-144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
@keyframes moveScale {
    0% {
        transform: scale(0.6);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(0.6);
    }
}
@keyframes moveLeftBounce {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes moveBounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

.hero-banner {
    overflow: hidden;
    height: 900px;
    position: relative;
    z-index: 1;
    
    &.banner-bg1 {
        background: transparent url(/images/banner-bg1.jpg) right top no-repeat;
        border-bottom: 1px solid #f7f7f7;
    }
    &.banner-bg2 {
        background: transparent url(/images/banner-bg2.jpg) left top no-repeat;
        border-bottom: 1px solid #f7f7f7;
    }
    .container-fluid {
        max-width: 1920px;  
    }
}
.hero-banner-content {
    max-width: 635px;
    margin: {
        top: 85px;
        left: auto;
    };
    .sub-title {
        color: $main-color;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        font: {
            size: 15px;
            weight: 600;
        }
    }
    h1 {
        line-height: 1.2;
        margin-bottom: 0;
        font: {
            weight: 800;
            size: 59px;
        }
    }
    p {
        font-size: 17px;
        margin: {
            top: 15px;
            bottom: 0;
        }
    }
    .btn-box {
        margin-top: 25px;
    }
    form {
        position: relative;
        margin: {
            top: 30px;
        }
        .input-newsletter {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: none;
            height: 65px;
            padding-left: 25px;
            border-radius: 70px;
            padding-top: 5px;
            outline: 0;
            color: $black-color;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;

            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            height: 55px;
            padding: 0 30px;
            border-radius: 70px;
            transition: $transition;
            line-height: 60px;
            text-transform: uppercase;
            font: {
                size: 15px;
                weight: 700;
            }
            &:hover {
                background-color: $optional-color;
                color: $white-color;
            }
        }
    }
}
.hero-banner-image {
    position: relative;
    text-align: center;
    margin: {
        left: 30px;
        right: 50px;
        top: 85px;
    }
    img {
        &:nth-child(2) {
            position: absolute;
            left: 0;
            bottom: 0;
        }
        &:nth-child(3) {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
}
.hero-main-banner-content {
    margin: {
        top: 85px;
    };
    .sub-title {
        color: $main-color;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        font: {
            size: 15px;
            weight: 600;
        }
    }
    h1 {
        line-height: 1.2;
        margin-bottom: 0;
        font: {
            weight: 800;
            size: 59px;
        }
    }
    p {
        font-size: 17px;
        margin: {
            top: 15px;
            bottom: 0;
        }
    }
    .btn-box {
        margin-top: 25px;
    }
    form {
        position: relative;
        margin: {
            top: 30px;
        }
        .input-newsletter {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: none;
            height: 65px;
            padding-left: 25px;
            border-radius: 70px;
            padding-top: 5px;
            outline: 0;
            color: $black-color;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;

            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            height: 55px;
            padding: 0 30px;
            border-radius: 70px;
            transition: $transition;
            line-height: 60px;
            text-transform: uppercase;
            font: {
                size: 15px;
                weight: 700;
            }
            &:hover {
                background-color: $optional-color;
                color: $white-color;
            }
        }
    }
}
.hero-main-banner-image {
    position: relative;
    right: -20px;
    margin: {
        top: 85px;
    };
    img {
        position: absolute;

        &:nth-child(1) {
            z-index: 3;
            top: -130px;
            left: -18px;
        }
        &:nth-child(2) {
            right: 145px;
            top: -287px;
        }
        &:nth-child(3) {
            left: 25px;
            top: -20px;
        }
        &:nth-child(4) {
            right: 12%;
            top: 118px;
            z-index: 1;
        }
        &:nth-child(5) {
            left: 28%;
            top: -120px;
            z-index: 2;
        }
        &:nth-child(6) {
            right: 35%;
            z-index: 1;
            top: -150px;
        }
        &:nth-child(7) {
            right: 20px;
            top: -110px;
        }
        &:nth-child(8) {
            right: 25px;
            top: -140px;
        }
        &:nth-child(9) {
            right: 30%;
            z-index: 1;
            top: -35px;
        }
        &:nth-child(10) {
            left: 37%;
            top: -28px;
            z-index: 1;
        }
        &:nth-child(11) {
            left: 37%;
            top: -160px;
            z-index: 1;
        }
        &:nth-child(12) {
            left: 90px;
            top: -110px;
        }
        &:nth-child(13) {
            left: 56%;
            top: -25px;
            z-index: 2;
        }
        &:nth-child(14) {
            right: 105px;
            top: -28px;
        }
        &:last-child {
            display: none;
        }
    }
}

/*================================================
Featured Services Area CSS
=================================================*/
.featured-services-area {
    padding-bottom: 70px;

    &.ptb-100 {
        &.pt-0 {
            padding-top: 40px !important;
        }
    }
}
.single-featured-box {
    margin-bottom: 30px;
    text-align: center;
    background-color: #f9f9fc;
    position: relative;
    padding: 115px 30px 35px 30px;
    overflow: hidden;
    transition: $transition;

    .icon {
        color: $optional-color;
        position: absolute;
        top: 33px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 55px;
        margin: {
            top: -12px;
        }
        &.color-fb7756 {
            color: #fb7756;
        }
        &.color-facd60 {
            color: #facd60;
        }
        &.color-1ac0c6 {
            color: #1ac0c6;
        }
    }
    h3 {
        margin-bottom: 0;
        font: {
            size: 20px;
        }
    }
    p {
        margin: {
            top: 10px;
            bottom: 0;
        }
    }
    .default-btn {
        margin-top: 20px;
        background-color: transparent;
        color: $main-color;
        border: 1px solid $main-color;
        font-size: 14px;
        padding: {
            top: 14px;
            bottom: 10px;
            left: 30px;
            right: 35px;
        };
        &:hover {
            box-shadow: 0 5px 28.5px 1.5px rgba(0, 0, 255, 0.2);
        }
        span {
            background: $main-color;
        }
    }
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        background-color: $white-color;

        .icon {
            animation: iconTop 0.4s ease-in-out;
        }
        .default-btn {
            background-color: $main-color;
            color: $white-color;
        }
    }
}
@keyframes iconTop {
    0% {
        transform: translate(-50%, 0);
    }
    25% {
        opacity: 0;
        transform: translate(-50%, -70%);
    }
    50% {
        opacity: 0;
        transform: translate(-50%, -40%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
    position: relative;
    z-index: 1;
}
.about-image {
    text-align: center;
}
.about-content {
    padding-left: 30px;

    .sub-title {
        color: $main-color;
        display: block;
        text-transform: uppercase;
        margin-bottom: 8px;
        font: {
            size: 15px;
            weight: 600;
        }
    }
    h2 {
        margin-bottom: 10px;
        font: {
            size: 40px;
        }
    }
    .features-list {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 10px;
            left: -10px;
            right: -10px;
        }
        li {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            margin-top: 10px;
            padding: {
                left: 10px;
                right: 10px;
            }
            span {
                display: block;
                background-color: #F8FAFF;
                border-radius: 5px;
                padding: 13px 12px 8px 40px;
                z-index: 1;
                position: relative;
                transition: $transition;
                font: {
                    weight: 600;
                    size: 15px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0;
                    height: 100%;
                    border-radius: 5px;
                    background-color: $main-color;
                    z-index: -1;
                    transition: $transition;
                }
                i {
                    font-size: 12px;
                    color: $white-color;
                    background-color: $main-color;
                    width: 23px;
                    height: 23px;
                    line-height: 23px;
                    transition: $transition;
                    text-align: center;
                    border-radius: 3px;
                    position: absolute;
                    left: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover {
                    color: $white-color;

                    &::before {
                        width: 100%;
                    }
                    i {
                        background-color: $optional-color;
                    }
                }
            }
        }
    }
}
.about-inner-area {
    margin-top: 100px;
}
.about-text {
    h3 {
        position: relative;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 24px;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 50px;
            background-color: $main-color;
            height: 1px;
        }
    }
    .features-list {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 15px;
            bottom: 0;
        }
        li {
            position: relative;
            margin-bottom: 12px;
            color: $black-color;
            padding: {
                top: 3px;
                left: 30px;
            }
            i {
                font-size: 8px;
                color: $black-color;
                background-color: #eaeaf2;;
                width: 20px;
                height: 20px;
                line-height: 20px;
                border-radius: 50%;
                transition: $transition;
                text-align: center;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
    padding-bottom: 70px;
    position: relative;
    z-index: 1;
}
.single-services-box {
    margin-bottom: 30px;
    background-color: $white-color;
    padding: 40px 30px;
    position: relative;
    transition: $transition;
    z-index: 1;
    text-align: center;

    .icon {
        width: 75px;
        height: 75px;
        text-align: center;
        line-height: 75px;
        background-color: rgba(250, 112, 112, 0.1);
        border-radius: 50%;
        color: $optional-color;
        transition: $transition;
        font-size: 35px;
        margin-bottom: 25px;
        margin-left: auto;
        margin-right: auto;

        &.bg-f78acb {
            background-color: rgba(247, 138, 203, 0.2);
            color: #f78acb;
        }
        &.bg-cdf1d8 {
            background: rgba(68, 206, 111, 0.2);
            color: #44ce6f;
        }
        &.bg-c679e3 {
            color: #c679e3;
            background: rgba(198, 121, 227, 0.2);
        }
        &.bg-eb6b3d {
            color: #eb6b3d;
            background: rgba(235, 107, 61, 0.2);
        }
        &.bg-00aeff {
            background: rgba(0, 174, 255, 0.2);
            color: #00aeff;
        }
    }
    h3 {
        transition: .4s;
        margin-bottom: 0;
        font: {
            size: 22px;
        }
        a {
            display: inline-block;
        }
    }
    p {
        transition: .4s;
        margin: {
            top: 10px;
            bottom: 0;
        }
    }
    .read-more-btn {
        color: $main-color;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transition: .6s;
        margin-left: auto;
        margin-right: auto;
        max-width: 100px;

        font: {
            size: $font-size;
            weight: 400;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 1px;
            width: 0;
            transition: $transition;
            height: 1px;
            background: $main-color;
        }
        i {
            font-size: 14px;
            padding-left: 6px;
        }
        &:hover {
            &::before {
                width: 100%;
            }
        }
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        z-index: -1;
        background-color: $main-color;
        transition: $transition;
    }
    &:hover {
        transform: translateY(-10px);

        &::before {
            width: 100%;
        }
        h3 {
            color: $white-color;
            transform: translateY(-15px);

            a {
                color: $white-color;
            }
        }
        p {
            transform: translateY(-15px);
            color: $white-color;
        }
        .icon {
            transform: translateY(-15px) rotate(360deg);
            background-color: $white-color;
            color: $main-color;
        }
        .read-more-btn {
            opacity: 1;
            visibility: visible;
            color: $white-color;
            bottom: 15px;

            &::before {
                background-color: $white-color;
            }
        }
    }
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-overview {
    -ms-flex-align: center!important;
    align-items: center!important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: {
        right: -25px;
        left: -25px;
        bottom: 60px;
    }
    &:last-child {
        margin-bottom: 0;
    }
    .services-details-desc {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            right: 25px;
            left: 25px;
        }
        h3 {
            margin-bottom: 15px;
            font: {
                size: 24px;
            }
        }
        .features-text {
            margin-top: 25px;
    
            h4 {
                margin-bottom: 15px;
                font: {
                    size: 18px;
                    weight: 600;
                }
                i {
                    font-size: 16px;
                    margin-right: 4px;
                    color: $main-color;
                }
            }
        }
        .services-details-accordion {
            margin-top: 25px;

            .accordion {
                list-style-type: none;
                padding-left: 0;
                margin: {
                    bottom: 0;
                }
                .accordion-item {
                    display: block;
                    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
                    background: $white-color;
                    margin-bottom: 10px;
        
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8) {
                        .accordion-title {
                            i {
                                background: $optional-color;
                            }
                        }
                    }
                }
                .accordion-title {
                    padding: 15px 20px 10px 51px;
                    color: $black-color;
                    position: relative;
                    border-bottom: 1px solid transparent;
                    margin-bottom: -1px;
                    display: inline-block;
                    font: {
                        size: $font-size;
                    }
                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 40px;
                        text-align: center;
                        height: 100%;
                        background: $main-color;
                        color: $white-color;
        
                        &::before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            font-size: 13px;
                        }
                    }
                    &.active {
                        border-bottom-color: #eeeeee;
        
                        i {
                            &::before {
                                content: "\f068";
                            }
                        }
                    }
                }
                .accordion-content {
                    display: none;
                    position: relative;
                    padding: 15px 15px;
        
                    &.show {
                        display: block;
                    }
                }
            }
        }
    }
    .services-details-image {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            right: 15px;
            left: 15px;
        }
    }
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.why-choose-content {
    padding-right: 30px;

    .sub-title {
        color: $main-color;
        display: block;
        text-transform: uppercase;
        margin-bottom: 8px;
        font: {
            size: 15px;
            weight: 600;
        }
    }
    h2 {
        margin-bottom: 10px;
        font: {
            size: 40px;
        }
    }
    .features-text {
        margin-top: 25px;

        h4 {
            margin-bottom: 15px;
            font: {
                size: 18px;
                weight: 600;
            }
            i {
                font-size: 16px;
                margin-right: 4px;
                color: $main-color;
            }
        }
    }
}
.why-choose-image {
    text-align: center;
}

/*================================================
Overview Area CSS
=================================================*/
.overview-box {
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: {
        right: -15px;
        left: -15px;
        bottom: 100px;
    }
    &:last-child {
        margin-bottom: 0;
    }
    .overview-content {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            left: 15px;
            right: 15px;
        }
        .content {
            padding-right: 30px;

            .sub-title {
                color: $main-color;
                display: block;
                text-transform: uppercase;
                margin-bottom: 8px;
                font: {
                    size: 15px;
                    weight: 600;
                }
            }
            h2 {
                margin-bottom: 10px;
                font: {
                    size: 35px;
                }
            }
            .features-text {
                margin-top: 25px;
        
                h4 {
                    margin-bottom: 15px;
                    font: {
                        size: 18px;
                        weight: 600;
                    }
                    i {
                        font-size: 16px;
                        margin-right: 4px;
                        color: $main-color;
                    }
                }
            }
            &.right-content {
                padding: {
                    left: 30px;
                    right: 0;
                }
            }
            .features-list {
                display: -ms-flexbox;
                display: -webkit-box;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                padding-left: 0;
                list-style-type: none;
                margin: {
                    bottom: 0;
                    top: 10px;
                    left: -10px;
                    right: -10px;
                }
                li {
                    -ms-flex: 0 0 50%;
                    -webkit-box-flex: 0;
                    flex: 0 0 50%;
                    max-width: 50%;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    padding: {
                        left: 10px;
                        right: 10px;
                    }
                    span {
                        display: block;
                        background-color: #F8FAFF;
                        border-radius: 5px;
                        padding: 13px 12px 8px 40px;
                        z-index: 1;
                        position: relative;
                        transition: $transition;
                        font: {
                            weight: 600;
                            size: 15px;
                        }
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 0;
                            height: 100%;
                            border-radius: 5px;
                            background-color: $main-color;
                            z-index: -1;
                            transition: $transition;
                        }
                        i {
                            font-size: 12px;
                            color: $white-color;
                            background-color: $main-color;
                            width: 23px;
                            height: 23px;
                            line-height: 23px;
                            transition: $transition;
                            text-align: center;
                            border-radius: 3px;
                            position: absolute;
                            left: 10px;
                            top: 9px;
                        }
                        &:hover {
                            color: $white-color;
        
                            &::before {
                                width: 100%;
                            }
                            i {
                                background-color: $optional-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .overview-image {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            left: 15px;
            right: 15px;
        }
        .image {
            text-align: center;
        }
    }
}

/*================================================
Fun Facts Area CSS
=================================================*/
.fun-facts-area {
    position: relative;
    z-index: 1;
    background: $main-color;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .40;
        height: 100%;
        z-index: -1;
        background: {
            image: url(/images/bg_lines.svg);
            repeat: no-repeat;
            position: center;
            size: cover;
        }
    }
}
.single-fun-facts {
    overflow: hidden;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: 30px;
    border: 1px dashed rgba(255, 255, 255, .4);
    border-radius: 5px;
    transition: $transition;

    h3 {
        position: relative;
        color: $white-color;
        margin-bottom: 0;
        font: {
            size: 50px;
        }
        .sign-icon {
            display: inline-block;
            font-size: 18px;
            margin-left: 2px;
        }
    }
    p {
        line-height: initial;
        margin-top: 5px;
        color: $white-color;
        font: {
            size: 17px;
            weight: 600;
        }
    }
    .back-text {
        position: absolute;
        left: 0;
        color: $white-color;
        right: 0;
        top: -5px;
        z-index: -1;
        opacity: .13;
        line-height: initial;
        font: {
            size: 150px;
            weight: 700;
            style: italic;
        }
    }
    &:hover {
        background-color: $main-color;
        transform: translateY(-10px);
        border-color: $white-color;
    }
}

/*================================================
Projects Area CSS
=================================================*/
.projects-area {
    padding-bottom: 70px;
    position: relative;
    z-index: 1;
    .container-fluid {
        max-width: 1920px;
        padding-left: 30px;
        padding-right: 30px;
    }
}
.single-projects-box {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;

    .projects-content {
        position: absolute;
        left: 0;
        bottom: 15px;
        background-color: $white-color;
        height: auto;
        width: 100%;
        padding: 25px 20px 20px;
        transition: transform .3s ease,opacity .3s;
        max-width: 370px;
        transform: translate3d(-100%, 0, 0);
        
        h3 {
            margin-bottom: 0;
            transform: translateX(20px);
            transition: transform .3s ease,opacity .3s;
            opacity: 0;
            font: {
                size: 24px;
            }
        }
        .category {
            display: block;
            margin-top: 8px;
            font-size: $font-size;
            color: $paragraph-color;
            opacity: 0;
            transform: translateX(20px);
            transition: transform .4s ease;
        }
    }
    .plus-icon {
        a {
            background-color: $main-color;
            width: 85px;
            height: 85px;
            position: absolute;
            top: 25px;
            right: 25px;
            transform: scale(0);
            transition: all .4s ease-out 0s;
            overflow: hidden;
            border-radius: 50%;

            span {
                width: 35px;
                height: 35px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);

                &::after, &::before {
                    position: absolute;
                    content: '';
                    width: 2px;
                    height: 100%;
                    background-color: $white-color;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
                &::after {
                    height: 2px;
                    width: 100%;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
            &:hover {
                background-color: $optional-color;
            }
        }
    }
    &:hover {
        .projects-content {
            transition: transform .5s ease;
            transform: translate3d(0, 0, 0);

            h3 {
                opacity: 1;
                transform: translateX(0);
                transition: transform .5s ease .5s,opacity .5s .3s;
            }
            .category {
                opacity: 1;
                transform: translateX(0);
                transition: transform .5s ease .6s,opacity .5s .6s;
            }
        }
        .plus-icon {
            a {
                transform: scale(1);
            }
        }
    }
}
.projects-slides {
    .swiper-pagination {
        position: initial;

        .swiper-pagination-bullet {
            width: 14px;
            height: 14px; 
            background: #D6D6D6; 
            transition: $transition; 
            opacity: 1;

            &.swiper-pagination-bullet-active, &:hover {
                background-color: $main-color;
            }
        }
    }
}

/*================================================
Projects Details Area CSS
=================================================*/
.project-details-image {
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;

    img {
        transition: $transition;
    }
    .btn {
        position: absolute; 
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 75px;
        height: 75px;
        line-height: 75px;
        font-size: 30px;
        background-color: $main-color;
        border-radius: 50%;
        text-align: center;
        color: $white-color;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
        margin-top: 20px;
        padding: 0;

        &:hover {
            background-color: $optional-color;
            color: $white-color;
        }
    }
    &:hover {
        .btn {
            margin-top: 0;
            opacity: 1;
            visibility: visible;
        }
        img {
            transform: scale(1.3) rotate(4deg);
        }
    }
}
.projects-details-desc {
    margin-top: 5px;

    h3 {
        margin-bottom: 13px;
        font: {
            size: 24px;
        }
    }
    .features-text {
        margin: {
            top: 25px;
            bottom: 25px;
        }
        h4 {
            margin-bottom: 12px;
            font: {
                size: 17px;
                weight: 600;
            }
            i {
                font-size: 16px;
                margin-right: 4px;
                color: $main-color;
            }
        }
    }
    .project-details-info {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            right: -15px;
            left: -15px;
            top: 35px;
        }
        .single-info-box {
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
            padding: {
                left: 15px;
                right: 15px;
            }
            h4 {
                margin-bottom: 10px;
                font: {
                    size: 19px;
                }
            }
            span {
                display: block;
                color: $paragraph-color;
                font-size: 15px;
            }
            .social {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    margin-right: 15px;

                    a {
                        color: $paragraph-color;
                        display: inline-block;

                        &:hover {
                            color: $main-color;
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Features Area CSS
=================================================*/
.features-area {
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.tab {
    .tabs_item {
        display: none;
        
        &:first-child {
            display: block;
        }
    }
}
.features-list-tab {
    .react-tabs__tab-list {
        border: none;
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
        margin: {
            left: -5px;
            right: -5px;
            bottom: 40px;
        }
        li {
            -ms-flex: 0 0 16.6666666667%;
            -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
            max-width: 16.6666666667%;
            text-align: center;
            border: none;
            padding: {
                right: 5px;
                left: 5px;
            }
            &::after {
                background-color: initial;
            }
            div {
                display: block;
                cursor: pointer;
                border-radius: 2px;
                box-shadow: 0px 10px 40px 0px rgba(107, 125, 179, 0.11);
                padding: {
                    right: 18px;
                    top: 15px;
                    bottom: 18px;
                    left: 18px;
                }
                font: {
                    size: 17px;
                    weight: 700;
                }
                i {
                    color: $main-color;
                    transition: $transition;
                    font: {
                        weight: normal;
                        size: 40px;
                    }
                }
                &.bg-fa7070 {
                    i {
                        color: #fa7070;
                    }
                }
                &.bg-00aeff {
                    i {
                        color: #00aeff;
                    }
                }
                &.bg-c679e3 {
                    i {
                        color: #c679e3;
                    }
                }
                &.bg-eb6b3d {
                    i {
                        color: #eb6b3d;
                    }
                }
                &.bg-f78acb {
                    i {
                        color: #f78acb;
                    }
                }
                span {
                    display: block;
                    margin-top: 3px;
                }
            }
            &.react-tabs__tab--selected, &:hover {
                div {
                    background-color: $main-color;
                    color: $white-color;
                    box-shadow: 0px 10px 40px 0px rgba(0, 0, 255, 0.22);

                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }
    .features-overview {
        -ms-flex-align: center!important;
        align-items: center!important;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            right: -15px;
            left: -15px;
        }
        .overview-content {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            padding: {
                left: 15px;
                right: 15px;
            }
            .content {
                padding: {
                    left: 15px;
                    right: 15px;
                }
                .sub-title {
                    color: $main-color;
                    display: block;
                    text-transform: capitalize;
                    margin-bottom: 8px;
                    font: {
                        size: 15px;
                        weight: 600;
                    }
                }
                h2 {
                    margin-bottom: 10px;
                    font: {
                        size: 30px;
                    }
                }
                .features-list {
                    display: -ms-flexbox;
                    display: -webkit-box;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    padding-left: 0;
                    list-style-type: none;
                    margin: {
                        bottom: 0;
                        top: 10px;
                        left: -10px;
                        right: -10px;
                    }
                    li {
                        -ms-flex: 0 0 50%;
                        -webkit-box-flex: 0;
                        flex: 0 0 50%;
                        max-width: 50%;
                        margin-top: 10px;
                        padding: {
                            left: 10px;
                            right: 10px;
                        }
                        span {
                            display: block;
                            background-color: #F8FAFF;
                            border-radius: 5px;
                            padding: 13px 12px 8px 40px;
                            z-index: 1;
                            position: relative;
                            transition: $transition;
                            font: {
                                weight: 600;
                                size: 15px;
                            }
                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 0;
                                height: 100%;
                                border-radius: 5px;
                                background-color: $main-color;
                                z-index: -1;
                                transition: $transition;
                            }
                            i {
                                font-size: 12px;
                                color: $white-color;
                                background-color: $main-color;
                                width: 23px;
                                height: 23px;
                                line-height: 23px;
                                transition: $transition;
                                text-align: center;
                                border-radius: 3px;
                                position: absolute;
                                left: 8px;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            &:hover {
                                color: $white-color;
            
                                &::before {
                                    width: 100%;
                                }
                                i {
                                    background-color: $white-color;
                                    color: $optional-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .overview-image {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            padding: {
                left: 15px;
                right: 15px;
            }
            .image {
                padding: {
                    left: 15px;
                    right: 15px;
                }
            }
        }
    }
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.single-team-box {
    margin-bottom: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    background-color: $white-color;
    transition: $transition;
    text-align: center;

    .image {
        position: relative;
        overflow: hidden;

        img {
            transition: $transition;
        }
        .social {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 2;
            text-decoration: none;
            opacity: 0;
            margin-top: -100px;
            padding: 10px 15px 5px;
            background: $white-color;
            border-radius: 35px;
            visibility: hidden;
            transition: $transition;

            a {
                display: inline-block;
                color: $main-color;
                font-size: 18px;
                margin: 0 6px;

                &:hover {
                    color: $optional-color;
                }
            }
        }
    }
    .content {
        padding: 25px;
        position: relative;
        transition: $transition;

        h3 {
            margin-bottom: 0;
            transition: $transition;
            font: {
                size: 24px;
            }
        }
        span {
            display: block;
            margin-top: 8px;
            color: $main-color;
            transition: $transition;
            font: {
                weight: 500;
            }
        }
    }
    &:hover {
        transform: translateY(-5px);
        
        .image {
            img {
                transform: scale(1.2);
            }
            .social {
                opacity: 1;
                margin-top: 0;
                visibility: visible;
            }
        }
        .content {
            background-color: $main-color;

            h3 {
                color: $white-color;
            }
            span {
                color: $white-color;
            }
        }
    }
}

/*================================================
Solution Area CSS
=================================================*/
.solution-area {
    position: relative;
    z-index: 1;
    background: {
        color: $main-color;
        position: center center;
        size: cover;
        repeat: no-repeat;
        attachment: fixed;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: -1;
        height: 100%;
        background-color: #000000;
        opacity: 0.8;
    }
    &.extra-pb {
        padding-bottom: 200px;
    }
}
.solution-content {
    .sub-title {
        color: $optional-color;
        display: block;
        text-transform: uppercase;
        margin-bottom: 10px;
        font: {
            size: 15px;
            weight: 600;
        }
    }
    h2 {
        color: $white-color;
        margin-bottom: 12px;
        font: {
            weight: 800;
            size: 50px;
        }
    }
    p {
        color: #e8e3e3;
    }
    .default-btn {
        margin-top: 10px;
    }
}
.solution-video {
    text-align: center;

    .video-btn {
        display: inline-block;
        width: 90px;
        height: 90px;
        line-height: 90px;
        background-color: $white-color;
        border-radius: 50%;
        color: $main-color;
        position: relative;
        z-index: 1;
        cursor: pointer;

        i {
            padding-left: 5px;
            &::before {
                font-size: 38px;
            }
        }
        &::after, &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $white-color;
        }
        &::before {
            animation: ripple 2s linear infinite
        }
        &::after {
            animation: ripple 2s linear 1s infinite
        }
        &:hover, &:focus {
            background-color: $main-color;
            color: $white-color;
        }
    }
}
@keyframes ripple {
    0% {
        transform: scale(1)
    }
    75% {
        transform: scale(1.75);
        opacity: 1
    }
    100% {
        transform: scale(2);
        opacity: 0
    }
}


/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
    padding-bottom: 70px;
    position: relative;
    z-index: 1;

    &.bg-color {
        margin-top: -100px;

        .section-title {
            margin-bottom: 30px;
    
            .sub-title {
                color: $white-color;
            }
            h2 {
                color: $white-color;
            }
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 60%;
            right: 0;
            z-index: -1;
            background-color: $main-color;
            margin: {
                left: 50px;
                right: 50px;
            }
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: .40;
            height: 60%;
            z-index: -1;
            background: {
                image: url(/images/bg_lines.svg);
                repeat: no-repeat;
                position: center;
                size: cover;
            }
            margin: {
                left: 50px;
                right: 50px;
            }
        }
    }
}
.single-feedback-item {
    position: relative;
    z-index: 1;
    background-color: $white-color;
    box-shadow: 0 0 15px #f1f1f1;
    padding: 30px;
    margin: {
        bottom: 30px;
    }
    p {
        color: #565968;
        margin-bottom: 0;
        font: {
            weight: normal;
            style: italic;
            size: 18px;
        }
    }
    .client-info {
        margin-top: 30px;
        position: relative;
        padding-left: 90px;

        img {
            width: 70px !important;
            height: 70px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 20px;
            }
        }
        span {
            display: block;
            color: $main-color;
            margin-top: 3px;
            font-size: 15px;
        }
    }
}
.feedback-slides {
    margin: {
        left: 80px;
        right: 80px;
    }
    max-width: 1920px;

    .single-feedback-item {
        box-shadow: 0px 5px 15px #f1f1f1;
        margin: {
            top: 30px;
            bottom: 30px;
        }
    }
    .swiper-pagination {
        position: initial;

        .swiper-pagination-bullet {
            width: 14px;
            height: 14px; 
            background: #D6D6D6; 
            opacity: 1;

            &.swiper-pagination-bullet-active, &:hover {
                background-color: $main-color;
            }
        }
    }
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-area {
    padding-bottom: 70px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: {
        image: url(/images/shape-wave.png);
        size: cover;
        repeat: no-repeat;
    }
}
.single-pricing-box {
    margin-bottom: 30px;
    text-align: center;
    background-color: $white-color;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
    padding: 40px 30px 28px 30px;
    border-radius: 5px;
    transition: $transition;

    .pricing-header {
        position: relative;
        z-index: 1;
        background-color: $main-color;
        margin: -40px -30px 30px -30px;
        border-radius: 5px 5px 0 0;
        padding: {
            left: 30px;
            right: 30px;
            top: 30px;
            bottom: 25px;
        }
        h3 {
            color: $white-color;
            font: {
                size: 24px;
            }
            margin: {
                bottom: 0;
            }
        }
    }
    .price {
        color: $black-color;
        line-height: 50px;
        align-items: baseline;
        position: relative;
        z-index: 1;
        font: {
            size: 50px;
            weight: 700;
        }
        sub {
            line-height: 16px;
            color: $paragraph-color;
            margin: 0 8px 0 6px;
            position: relative;
            top: -4px;
            display: inline-block;
            font: {
                weight: 600;
                size: 16px;
            }
        }
    }
    .price-features-list {
        text-align: left;
        padding: 30px 30px;
        margin: 25px -30px 30px -30px;
        list-style-type: none;
        border: {
            bottom: 1px solid #eeeeee;
            top: 1px solid #eeeeee;
        }
        li {
            position: relative;
            padding: {
                top: 8px;
                bottom: 8px;
                right: 25px;
            }
            color: $paragraph-color;
            font: {
                weight: 500;
                size: 17px;
            }
            i {
                font-size: 14px;
                color: $main-color;
                position: absolute;
                right: 0;
                top: 10px;

                &.flaticon-tick {
                    color: #6ac04f;
                }
                &.flaticon-close {
                    color: #dd5252;
                    font-size: 13px;
                    top: 12px;
                }
            }
        }
    }
    .get-started-btn {
        display: inline-block;
        color: $main-color;
        position: relative;
        font: {
            weight: 600;
            size: 18px;
        }
        &::before {
            width: 0;
            height: 1px;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $main-color;
            transition: $transition;
        }
        &:hover {
            &::before {
                width: 100%;
            }
        }
    }
    &.red {
        .pricing-header {
            background-color: #fc6765;
        }
        .get-started-btn {
            color: #fc6765;

            &::before {
                background-color: #fc6765;
            }
        }
    }
    &.orange {
        .pricing-header {
            background-color: #ffa143;
        }
        .get-started-btn {
            color: #ffa143;

            &::before {
                background-color: #ffa143;
            }
        }
    }
    &:hover {
        transform: translateY(-10px);
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding-bottom: 90px;

    .row {
        margin: {
            left: -5px;
            right: -5px;
        };
    }
}
.single-partner-item {
    text-align: center;
    margin-bottom: 10px;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    padding: {
        left: 5px;
        right: 5px;
    }
    a {
        display: block;
        position: relative;
        overflow: hidden;
        background-color: #F4F7FC;
        border-radius: 5px;
        padding: 20px;

        img {
            &:nth-child(1) {
                position: relative;
                display: inline-block !important;
                width: auto !important;
                transition: transform .4s ease;
            }
            &:nth-child(2) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 200%);
                transition: transform .4s ease;
                display: inline-block !important;
                width: auto !important;
            }
        }
        &:hover {
            img {
                &:nth-child(1) {
                    transform: translateY(-200%);
                }
                &:nth-child(2) {
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}
.partner-slides {
    .single-partner-item {
        margin-bottom: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding: {
            left: 0;
            right: 0;
        }
    }
    .swiper-button-prev, .swiper-button-next {
        background-color: $main-color;
        color: #fff;
        width: 30px;
        height: 30px;
        transition: $transition;
        border-radius: 4px;
        visibility: hidden;

        &::after {
            font-size: 17px;
        }
        &:hover {
            background-color: $black-color;
        }
        &.swiper-button-disabled, &.swiper-button-disabled {
            pointer-events: initial;
        }
    }
    
    &:hover {
        .swiper-button-prev, .swiper-button-next {
            visibility: visible;
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
    position: relative;
    z-index: 1;
    padding-bottom: 70px;
}
.single-blog-post {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 10px 55px 5px rgba(137, 173, 255, .2);

    .post-image {
        overflow: hidden;
        
        a {
            display: block;

            img {
                transition: $transition;
            }
        }
    }
    .post-content {
        padding: 30px;
        position: relative;
        z-index: 1;

        .post-meta {
            transition: $transition;
            margin-bottom: 10px;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    margin-right: 20px;
                    font-size: 14px;
                    color: $paragraph-color;
                    position: relative;

                    &::before {
                        position: absolute;
                        right: -11px;
                        top: 3px;
                        width: 1px;
                        height: 13px;
                        background-color: $paragraph-color;
                        content: '';
                        transform: rotate(10deg);
                    }
                    a {
                        color: $optional-color;
                        display: inline-block;
                        margin-left: 3px;
                    }
                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        h3 {
            transition: $transition;
            margin-bottom: 8px;
            line-height: 1.4;
            font: {
                size: 24px;
            }
            a {
                display: inline-block;
            }
        }
        p {
            transition: $transition;
            margin: {
                bottom: 0;
            }
        }
        .read-more-btn {
            color: $main-color;
            display: inline-block;
            position: absolute;
            bottom: 15px;
            left: 30px;
            opacity: 0;
            visibility: hidden;
            font-size: 15px;
            transition: $transition;
            font: {
                weight: 400;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 1px;
                width: 0;
                transition: $transition;
                height: 1px;
                background: $main-color;
            }
            i {
                font-size: 14px;
            }
            &:hover {
                &::before {
                    width: 100%;
                }
            }
        }
    }
    &:hover {
        .post-image {
            a {
                img {
                    transform: scale(1.2) rotate(4deg);
                }
            }
        }
        .post-content {
            .post-meta {
                transform: translateY(-35px);
                opacity: 0;
                visibility: hidden;
            }
            h3 {
                transform: translateY(-35px);
            }
            P {
                transform: translateY(-35px);  
            }
            .read-more-btn {
                opacity: 1;
                visibility: visible;
                bottom: 23px;
            }
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    .article-content {
        margin-top: 30px;

        .entry-meta {
            margin-bottom: -8px;
    
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
    
                li {
                    position: relative;
                    display: inline-block;
                    color: $black-color;
                    margin-right: 25px;
                    font-size: 15px;

                    span {
                        display: inline-block;
                        color: $black-color;
                        font-weight: 500;
                    }
                    a {
                        display: inline-block;
                        color: $paragraph-color;
                        padding-left: 5px;
    
                        &:hover {
                            color: $main-color;
                        }
                    }
                    i {
                        color: $main-color;
                        margin-right: 2px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 10px;
                        right: -15px;
                        width: 6px;
                        height: 1px;
                        background: $main-color;
                    }
                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        h3 {
            margin: {
                bottom: 15px;
                top: 25px;
            }
            font: {
                size: 24px;
            }
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    -ms-flex: 0 0 33.3333%;
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            }
            li {
                margin-bottom: 16px;
                position: relative;
                padding-left: 34px;
                color: $paragraph-color;

                i {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
                    background-color: #faf5f5;
                    color: $main-color;
                    transition: $transition;
                    display: inline-block;
                    font-size: 11px;
                    position: absolute;
                    left: 0;
                    top: -2px;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            top: 30px;
        }
        .article-tags {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            color: $paragraph-color;
            
            span {
                display: inline-block;
                color: $black-color;
                font-size: 20px;
                margin-right: 10px;
                position: relative;
                top: 2px;
            }
            a {
                display: inline-block;
                color: $paragraph-color;
                padding-right: 5px;
                &:hover {
                    color: $main-color;
                }
            }
        }
        .article-share {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            
            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: right;
                margin: {
                    bottom: 0;
                }
                li {
                    display: inline-block;
                    margin-right: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                    span {
                        display: inline-block;
                        margin-right: 2px;
                        font-weight: 500;
                        color: $paragraph-color;
                    }
                    a {
                        display: block;
                        color: $main-color;
                        width: 32px;
                        height: 32px;
                        line-height: 36px;
                        border-radius: 50%;
                        background-color: #eeeef0;
                        text-align: center;
                        font-size: 12px;
        
                        &:hover {
                            color: $white-color;
                            background-color: $main-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
    .post-navigation {
        margin-top: 30px;
    }
}
blockquote, .blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    }
    p {
        color: $black-color;
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 600;
            size: 24px !important;
        }
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        content: "\f10e";
        position: absolute;
        left: 50px;
        top: -50px;
        z-index: -1;
        font: {
            family: "Font Awesome 5 Free";
            size: 140px;
            weight: 900;
        }
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $main-color;
        margin: {
            top: 20px;
            bottom: 20px;
        }
    }
}
.post-navigation {
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    }
    padding: {
        top: 20px;
        bottom: 20px;
    }
    .navigation-links {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .nav-previous {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            
            a {
                i {
                    margin-right: 2px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
        .nav-next {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            text-align: right;
            
            a {
                i {
                    margin-left: 2px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        margin-left: 0;
                    }
                }
            }
        }
        div {
            a {
                display: inline-block;
                font: {
                    weight: 600;
                }
            }
        }
    }
}
.comments-area {
    padding: 25px;
    margin-top: 30px;
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
    background-color: $white-color;

    .comments-title {
        position: relative;
        margin: {
            bottom: 30px;
        }
        line-height: initial;
        font: {
            size: 20px;
            weight: 700;
        }
        &::before {
            content: '';
            height: 25px;
            width: 3px;
            left: -25px;
            position: absolute;
            background: $main-color;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    ol, ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .children {
        margin-left: 20px;
    }
    .comment-body {
        border-bottom: 1px solid #eeeeee;
        padding-left: 65px;
        color: $black-color;
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .comment-content {
            p {
                font-size: 15px;
            }
        }

        .reply {
            margin-top: 15px;

            a {
                border: 1px solid #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 10px 20px 7px;
                border-radius: 30px;
                text-transform: uppercase;
                position: relative;
                z-index: 1;
                font: {
                    size: 12px;
                    weight: 700;
                }
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .comment-author {
        font-size: 17px;
        margin-bottom: 8px;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 700;
            }
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        margin-bottom: .8em;
        color: $paragraph-color;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 13px;
            weight: 600;
        }
        a {
            color: $paragraph-color;

            &:hover {
                color: $main-color;
            }
        }
    }
    .comment-respond {
        margin-top: 30px;

        .comment-reply-title {
            margin-bottom: 0;
            position: relative;
            font: {
                size: 18px;
                weight: 700;
            }
            &::before {
                content: '';
                height: 25px;
                width: 3px;
                left: -25px;
                position: absolute;
                background: $main-color;
                top: 50%;
                transform: translateY(-50%);
            }
            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            font-size: 15px;
            margin: {
                bottom: 0;
                top: 10px;
            }
            .required {
                color: red;
            }
        }
        .comment-form-comment {
            margin-top: 15px;
            float: left;
            width: 100%;
        }
        label {
            display: block;
            font-weight: 600;
            color: $black-color;
            margin-bottom: 5px;
            font-size: 15px;
        }
        input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: 1px solid #eeeeee;
            padding: 0.625em 0.7375em;
            outline: 0;
            transition: $transition;

            &:focus {
                border-color: $main-color;
            }
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;

            input {
                position: absolute;
                left: 0;
                top: 6px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $paragraph-color;
                font-weight: normal;
            }
        }
        .form-submit {
            float: left;
            width: 100%;

            input {
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 12px 25px 10px;
                display: inline-block;
                cursor: pointer;
                outline: 0;
                border-radius: 0;
                text-transform: uppercase;
                transition: $transition;
                font: {
                    weight: 600;
                    size: 14px;
                }
                &:hover, &:focus {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.subscribe-inner-area {
    position: relative;
    z-index: 1;
    padding: 80px;
    background: {
        color: $main-color;
        position: center center;
        size: cover;
        repeat: no-repeat;
        attachment: fixed;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: -1;
        height: 100%;
        background-color: $main-color;
        opacity: 0.8;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .70;
        height: 100%;
        z-index: -1;
        background: {
            image: url(/images/bg_lines.svg);
            repeat: no-repeat;
            position: center;
            size: cover;
        }
    }
}
.subscribe-content {
    text-align: center;

    .sub-title {
        color: $white-color;
        display: block;
        text-transform: uppercase;
        margin-bottom: 10px;
        font: {
            size: 15px;
            weight: 600;
        }
    }
    h2 {
        color: $white-color;
        max-width: 700px;
        margin: {
            bottom: 0;
            left: auto;
            right: auto;
        }
        font: {
            weight: 800;
            size: 40px;
        }
    }
    .newsletter-form {
        max-width: 550px;
        position: relative;
        margin: {
            left: auto;
            right: auto;
            top: 35px;
        }
        .input-newsletter {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: none;
            height: 65px;
            padding-left: 25px;
            border-radius: 70px;
            padding-top: 5px;
            outline: 0;
            color: $black-color;
            font-size: 15px;

            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            height: 55px;
            padding: 0 30px;
            border-radius: 70px;
            transition: $transition;
            line-height: 60px;
            text-transform: uppercase;
            font: {
                size: 15px;
                weight: 700;
            }
            &:hover {
                background-color: $optional-color;
                color: $white-color;
            }
        }
        #validator-newsletter {
            position: absolute;
            left: 0;
            margin: 0 auto;
            right: 0;
            color: $white-color;
            bottom: -35px;
        }
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    height: 400px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #fbfbfb;

    &.page-title-bg1 {
        background: transparent url(/images/banner-bg1.jpg) right top no-repeat;
    }
    &.page-title-bg2 {
        background: transparent url(/images/banner-bg2.jpg) left top no-repeat;
    }
    .shape-img7 {
        left: 75%;
    }
}
.page-title-content {
    position: relative;
    margin-top: 70px;
    text-align: center;

    h2 {
        margin-bottom: 0;
        position: relative;
        padding-bottom: 10px;
        font: {
            size: 40px;
            weight: 800;
        }
        &::before {
            width: 30px;
            height: 2px;
            background-color: $optional-color;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
        }
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 20px;
        }
        li {
            position: relative;
            display: inline-block;
            color: $main-color;
            font-size: 16px;
            margin: {
                left: 12px;
                right: 16px;
            }
            a {
                display: inline-block;
                color: $paragraph-color;

                &:hover {
                    color: $optional-color;
                }
            }
            &::before {
                content: "\f054";
                position: absolute;
                right: -18px;
                top: 3px;
                color: $paragraph-color;
                font: {
                    weight: 900;
                    family: "Font Awesome 5 Free";
                    size: 12px;
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
    margin-top: 30px;
    text-align: center;

    .page-numbers {
        width: 45px;
        height: 45px;
        margin: 0 5px;
        display: inline-block;
        background-color: $white-color;
        line-height: 49px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        border-radius: 50%;
        font: {
            size: 13px;
            weight: 700;
        }
        &.current, &:hover, &:focus {
            background: $main-color;
            color: $white-color;
            box-shadow: 0 2px 10px 0 #d8dde6;
        }
    }
}

/*================================================
Sidebar Area CSS
=================================================*/
.widget-area {
    padding-left: 15px;
    .widget {
        margin-top: 35px;

        &:first-child {
            margin-top: 0;
        }
        .widget-title {
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
            margin-bottom: 20px;
            text-transform: capitalize;
            position: relative;
            font: {
                weight: 700;
                size: 20px;
            }
            &::before {
                content: '';
                position: absolute;
                background: $main-color;
                bottom: -1px;
                left: 0;
                width: 50px;
                height: 1px;
            }
        }
    }
    .widget_search {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 15px;

        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 6px 15px;
                border: 1px solid #eeeeee;
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;

                &:focus {
                    border-color: $main-color;
                }
            }
            button {
                position: absolute;
                right: 0;
                outline: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                z-index: 1;
                border: none;
                color: $white-color;
                background-color: $main-color;
                transition: $transition;

                &:hover {
                    background-color: $optional-color;
                    color: $white-color;
                }
            }
        }
    }
    .widget_posts_thumb {
        position: relative;
        overflow: hidden;
        
        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                time {
                    display: block;
                    color: $paragraph-color;
                    text-transform: uppercase;
                    margin: {
                        top: 5px;
                        bottom: 5px;
                    }
                    font: {
                        size: 11px;
                    }
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.5;
                    font: {
                        size: 15px;
                        weight: 700;
                    }
                    a {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .widget_recent_entries {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 20px;
                line-height: 1.6;
                font: {
                    weight: 600;
                    size: 15px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 10px;
                    width: 10px;
                    content: '';
                    left: 0;
                    top: 4px;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-date {
                    display: block;
                    font-size: 13px;
                    color: $paragraph-color;
                    margin-top: 4px;
                }
            }
        }
    }
    .widget_recent_comments {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 20px;
                line-height: 1.6;
                font: {
                    weight: 600;
                    size: 15px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 10px;
                    width: 10px;
                    content: '';
                    left: 0;
                    top: 4px;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_archive {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                padding-left: 20px;
                color: $black-color;
                font: {
                    size: 15px;
                    weight: 600;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 10px;
                    width: 10px;
                    content: '';
                    left: 0;
                    top: 4px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 20px;
                font: {
                    size: 15px;
                    weight: 600;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 10px;
                    width: 10px;
                    content: '';
                    left: 0;
                    top: 4px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: block;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-count {
                    float: right;
                }
            }
        }
    }
    .widget_meta {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 20px;
                font: {
                    size: 15px;
                    weight: 600;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 10px;
                    width: 10px;
                    content: '';
                    left: 0;
                    top: 4px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 12px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            background-color: #fff;
            color: $black-color;
            font: {
                weight: 600;
                size: 14.4px !important;
            }
            padding: 10px 15px 7px;
            border: 1px dashed #eeeeee;
            margin: {
                top: 8px;
                right: 10px;
            }
            &:hover, &:focus {
                color: $white-color;
                background-color: $main-color;
                border-color: $main-color;
            }
        }
    }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    height: 100vh;
}
.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;

    h3 {
        font: {
            size: 40px;
        }
        margin: {
            top: 45px;
            bottom: 15px;
        }
    }
    p {
        max-width: 520px;
        margin: 0 auto 20px;
    }
}

/*================================================
Shop Area CSS
=================================================*/
.woocommerce-topbar {
    margin-bottom: 40px;

    .woocommerce-topbar-ordering {
        text-align: right;
    }
}
.single-product-box {
    text-align: center;
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);

    .product-image {
        overflow: hidden;
        position: relative;
        text-align: left;

        a {
            img {
                transition: $transition;

                &:nth-child(2) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    visibility: hidden;
                    margin-left: 20px;
                }
            }
        }
        .add-to-cart-btn {
            position: absolute;
            bottom: -46px;
            left: 0;
            width: 100%;
            background-color: $black-color;
            color: $white-color;
            padding: 18px 20px 13px;
            text-transform: uppercase;
            opacity: 0;
            visibility: hidden;
            font: {
                size: 15px;
                weight: 700;
            }
            i {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
        }
        .sale-btn {
            position: absolute;
            left: 20px;
            top: 20px;
            background-color: red;
            color: $white-color;
            width: 55px;
            height: 55px;
            line-height: 55px;
            text-align: center;
            border-radius: 50%;
            text-transform: uppercase;
            font: {
                weight: 700;
                size: 14px;
            }
        }
    }
    .product-content {
        padding: 30px 25px;

        h3 {
            margin-bottom: 12px;
            font: {
                size: 24px;
            }
            a {
                display: inline-block;
            }
        }
        .price {
            color: $black-color;
            font: {
                weight: 600;
                size: 15px;
            }
            span {
                margin: 0 3px;
            }
            .old {
                text-decoration: line-through;
                color: #828893;
                font: {
                    size: 14px;
                }
            }
        }
        .rating {
            margin-top: 12px;
            color: #ffb708;
            font: {
                size: 15px;
            }
            i {
                margin: 0 1px;
            }
        }
    }
    &:hover {
        .product-image {
            a {
                img {
                    &:nth-child(2) {
                        opacity: 1;
                        visibility: visible;
                        margin-left: 0;
                    }
                }
            }
            .add-to-cart-btn {
                bottom: 0;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*================================================
Product Details Area CSS
=================================================*/
.product-details-desc {
    h3 {
        margin-bottom: 12px;
        font: {
            size: 24px;
        }
    }
    .price {
        margin-bottom: 10px;
        color: $black-color;
        font: {
            size: 15px;
            weight: 600;
        }
        .old-price {
            text-decoration: line-through;
            color: #828893;
            margin-left: 10px;
        }
    }
    .product-review {
        margin-bottom: 15px;

        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 14px;

            i {
                color: #ffba0a;
                margin-right: 2px;
            }
        }
        .rating-count {
            display: inline-block;
            color: $black-color;
            border-bottom: 1px solid $black-color;
            line-height: initial;

            &:hover {
                color: $main-color;
                border-color: $main-color;
            }
        }
    }
    .product-add-to-cart {
        margin-top: 20px;

        .input-counter {
            max-width: 130px;
            min-width: 130px;
            margin-right: 15px;
            text-align: center;
            display: inline-block;
            position: relative;    

            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: #d0d0d0;
                width: 50px;
                height: 100%;
                line-height: 55px;
                transition: $transition;

                &.minus-btn {
                    left: 0;
                }
                &.plus-btn {
                    right: 0;
                }
                &:hover {
                    color: $main-color;
                }
            }
            input {
                height: 50px;
                color: $black-color;
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font: {
                    size: 17px;
                    weight: 600;
                }
                &::placeholder {
                    color: $black-color;
                }
            }
        }
        .default-btn {
            height: 50px;
            top: -1px;
            position: relative;
            line-height: 48px;
            border: none;
            padding: {
                top: 0;
                bottom: 0;
            }
            i {
                margin-right: 8px;
            }
        }
    }
    .buy-checkbox-btn {
        margin-top: 20px;

        input {
            display: none;
        }
        .cbx {
            margin: auto;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;

            span {
                display: inline-block;
                vertical-align: middle;
                transform: translate3d(0, 0, 0);

                &:first-child {
                    position: relative;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    transform: scale(1);
                    vertical-align: middle;
                    border: 1px solid #ebebeb;
                    transition: all 0.2s ease;
                    transition: $transition;

                    svg {
                        position: absolute;
                        top: 3px;
                        left: 2px;
                        fill: none;
                        stroke: $white-color;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 16px;
                        stroke-dashoffset: 16px;
                        transition: all 0.3s ease;
                        transition-delay: 0.1s;
                        transform: translate3d(0, 0, 0);
                        transition: $transition;
                    }
                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: $black-color;
                        display: block;
                        transform: scale(0);
                        opacity: 1;
                        border-radius: 50%;
                        transition: $transition;
                    }
                }
                &:last-child {
                    position: relative;
                    top: 2px;
                    padding-left: 10px;
                    color: $paragraph-color;
                }
            }
            &:hover {
                span {
                    &:first-child {
                        border-color: $black-color;
                    }
                }
            }
        }
        .inp-cbx:checked + .cbx span:first-child {
            background: $black-color;
            border-color: $black-color;
            animation: wave 0.4s ease;
        }
        .inp-cbx:checked + .cbx span:first-child svg {
            stroke-dashoffset: 0;
        }
        .inp-cbx:checked + .cbx span:first-child:before {
            transform: scale(3.5);
            opacity: 0;
            transition: all 0.6s ease;
        }
        .item {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }
        .btn-light {
            background-color: #f2f2f2;
            border: none;
            padding: 13px 25px 10px 25px;
            transition: $transition;
            font-weight: 600;
            display: block;
            width: 100%;

            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
    .custom-payment-options {
        margin-top: 20px;

        span {
            display: block;
            color: $paragraph-color;
        }
        .payment-methods {
            a {
                display: inline-block;
                margin-right: 10px;
                margin-top: 10px;

                img {
                    width: 40px;
                }
            }
        }
    }
}
.products-details-tab {
    margin-top: 50px;

    .tabs {
        list-style-type: none;
        margin-bottom: -1px;
        padding: {
            left: 0;
        }
        li {
            display: inline-block;
            line-height: initial;
            margin-right: 5px;

            span {
                display: inline-block;
                position: relative;
                text-transform: uppercase;
                color: $black-color;
                border: 1px dashed #eeeeee;
                cursor: pointer;

                padding: {
                    top: 17px;
                    bottom: 13px;
                    right: 30px;
                    left: 48px;
                }
                font: {
                    weight: 700;
                    size: 15px;
                }
                .dot {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 30px;
                    width: 12px;
                    height: 12px;
                    border: 1px solid $black-color;
                    transition: $transition;
                    border-radius: 50%;
        
                    &::before {
                        position: absolute;
                        top: 0;
                        content: '';
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: $black-color;
                        margin: 2px;
                        border-radius: 50%;
                        transition: $transition;
                    }
                }
                &:hover, &:focus {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;

                    .dot {
                        border-color: $white-color;

                        &::before {
                            background: $white-color;
                        }
                    }
                }
            }
            &.current {
                span {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;

                    .dot {
                        border-color: $white-color;

                        &::before {
                            background: $white-color;
                        }
                    }
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .tab_content {
        border: 1px dashed #eeeeee;
        padding: 30px;

        .tabs_item {
            .products-details-tab-content {
                p {
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .additional-information {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;
                    text-align: left;
        
                    li {
                        border: 1px solid #eeeeee;
                        border-bottom: none;
                        padding: 10px 15px 7px;
                        color: $paragraph-color;

                        &:last-child {
                            border-bottom: 1px solid #eeeeee;;
                        }
                        span {
                            display: inline-block;
                            width: 30%;
                            color: $black-color;
                            font: {
                                weight: 700;
                            }
                        }
                    }
                }
                h3 {
                    margin-bottom: 15px;
                    font: {
                        size: 24px;
                    }
                }
                .product-review-form {
                    .review-title {
                        position: relative;

                        .rating {
                            display: inline-block;

                            .fas.fa-star {
                                color: #ffba0a;
                            }
                            i {
                                color: #ebebeb;
                                font-size: 14px;
                            }
                        }
                        p {
                            margin-bottom: 0;
                            display: inline-block;
                            padding-left: 5px;
                            line-height: initial;
                        }
                        .default-btn {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            font-size: 15px;
                            padding-left: 20px;
                            padding-right: 20px;
                            padding-top: 14px;
                            padding-bottom: 10px;
                        }
                    }
                    .review-comments {
                        margin-top: 35px;
                        
                        .review-item {
                            margin-top: 30px;
                            position: relative;
                            padding-right: 200px;
                            border-top: 1px dashed #eeeeee;
                            padding-top: 30px;

                            .rating {
                                .fas.fa-star {
                                    color: #ffba0a;
                                }
                                i {
                                    font-size: 14px;
                                    color: #ebebeb;
                                    margin-right: 3px;
                                }
                            }
                            h3 {
                                font-size: 18px;
                                margin: {
                                    top: 10px;
                                    bottom: 10px;
                                }
                            }
                            span {
                                margin-bottom: 10px;
                                font-size: 13px;
                                display: block;

                                strong {
                                    font-weight: 600;
                                }
                            }
                            p {
                                margin-bottom: 0;
                            }
                            .review-report-link {
                                position: absolute;
                                right: 0;
                                color: $paragraph-color;
                                top: 40px;
                                text-decoration: underline;
                                font: {
                                    weight: 500;
                                    size: 15px;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                    }
                    .review-form {
                        margin-top: 30px;

                        h3 {
                            margin-bottom: 20px;
                        }
                        form {
                            .form-group {
                                margin-bottom: 20px;

                                .form-control {
                                    height: 55px;
                                    border: none;
                                    background: #f5f5f5;
                                    padding: 5px 20px;
                                }
                                textarea.form-control {
                                    padding-top: 15px;
                                    height: auto;
                                }
                            }
                            .default-btn {
                                border: none;
                                margin-top: 10px;
                                box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, .2);
                            }
                        }
                    }
                }
            }
        }
    }
}
.related-products {
    margin-top: 70px;

    .section-title {
        margin-bottom: 10px;
    }
    .single-product-box {
        margin: {
            bottom: 0;
            top: 30px;
        }
    }
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table {
    table {
        margin-bottom: 0;

        thead {
            tr {
                th {
                    border-bottom-width: 0px;
                    vertical-align: middle;
                    padding: 10px 0;
                    text-transform: uppercase;
                    border: none;
                    white-space: nowrap;
                    font: {
                        weight: 700;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    vertical-align: middle;
                    color: $paragraph-color;
                    white-space: nowrap;
                    padding: {
                        left: 0;
                        right: 0;
                    }
                    border: {
                        color: #eaedff;
                        left: none;
                        right: none;
                    }
                    &.product-thumbnail {
                        a {
                            display: block;

                            img {
                                width: 80px;
                            }
                        }
                    }
                    &.product-name {
                        a {
                            color: $paragraph-color;
                            font-weight: 500;
                            display: inline-block;

                            &:hover {
                                color: $black-color;
                            }
                        }
                    }
                    &.product-subtotal {
                        .remove {
                            color: red;
                            float: right;
                            position: relative;
                            top: -1px;
                        }
                    }
                    &.product-quantity {
                        .input-counter {
                            max-width: 130px;
                            min-width: 130px;
                            text-align: center;
                            display: inline-block;
                            position: relative;    
                
                            span {
                                position: absolute;
                                top: 0;
                                background-color: transparent;
                                cursor: pointer;
                                color: #d0d0d0;
                                width: 40px;
                                height: 100%;
                                line-height: 48px;
                                transition: $transition;
                
                                &.minus-btn {
                                    left: 0;
                                }
                                &.plus-btn {
                                    right: 0;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                            input {
                                height: 45px;
                                color: $black-color;
                                outline: 0;
                                display: block;
                                border: none;
                                background-color: #f8f8f8;
                                text-align: center;
                                width: 100%;
                                font: {
                                    size: 17px;
                                    weight: 600;
                                }
                                &::placeholder {
                                    color: $black-color;
                                }
                            }
                        }
                    }
                    &.product-subtotal {
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
.cart-buttons {
    margin-top: 30px;

    .shopping-coupon-code {
        position: relative;
        max-width: 530px;

        .form-control {
            height: 53px;
        }
        button {
            position: absolute;
            right: 0;
            top: 0;
            height: 53px;
            background: $black-color;
            color: $white-color;
            border: none;
            padding: 0 25px;
            line-height: 54px;
            font-weight: 600;
            outline: 0;
            transition: $transition;

            &:hover {
                background-color: $main-color;
            }
        }
    }
}
.cart-totals {
    background: $white-color;
    padding: 40px;
    max-width: 620px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: {
        left: auto;
        top: 50px;
    }
    h3 {
        margin-bottom: 25px;
        font: {
            size: 24px;
        }
    }
    ul {
        padding-left: 0;
        margin: 0 0 25px;
        list-style-type: none;
        
        li {
            border: 1px solid #eaedff;
            padding: 10px 15px;
            color: $black-color;
            overflow: hidden;
            font: {
                weight: 600;
            }
            &:first-child {
                border: {
                    bottom: none;
                }
            }
            &:last-child {
                border: {
                    top: none;
                }
            }
            b {
                font-weight: 500;
            }
            span {
                float: right;
                color: $paragraph-color;
                font: {
                    weight: normal;
                }
            }
        }
    }
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 18px 20px 15px;
    margin-bottom: 65px;
    border-top: 3px solid $main-color;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: -35px;
        width: 100%;
        height: 1px;
        background: #eeeeee;
    }
    i {
        color: $main-color;
        margin-right: 8px;
    }
    span {
        display: inline-block;
        font-weight: 500;
        color: $main-color;

        a {
            display: inline-block;
            color: $black-color;

            &:hover, &:focus {
                color: $main-color;
            }
        }
    }
}
.billing-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 24px;
            weight: 700;
        }
        &::before {
            content: '';
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .form-group {
        margin-bottom: 25px;

        label {
            display: block;
            margin-bottom: 7px;
            font: {
                weight: 500;
            }
            .required {
                color: red;
            }
        }
        .nice-select {
            float: unset;
            line-height: 53px;
            color: $paragraph-color;
            font: {
                weight: 500;
            }
            padding: {
                top: 0;
                bottom: 0;
            }
            .list {
                background-color: $white-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding: {
                    top: 10px;
                    bottom: 10px;
                }
                .option {
                    transition: $transition;
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                        font-weight: 600;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
        .form-control {
            height: 50px;
        }
        textarea.form-control {
            height: auto;
            padding-top: 15px;
        }
    }
    .form-check {
        margin-bottom: 20px;

        .form-check-label {
            color: $black-color;
        }
        label {
            position: relative;
            left: -3px;
            top: 1px;
            font: {
                weight: 500;
            }
        }
    }
    .col-lg-12 {
        &:last-child {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}
.order-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 24px;
            weight: 700;
        }
        &::before {
            content: '';
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .order-table {
        margin-bottom: 25px;
        table {
            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        text-transform: uppercase;
                        padding: {
                            left: 20px;
                            top: 15px;
                            right: 20px;
                            bottom: 10px;
                        }
                        font: {
                            weight: 600;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: $paragraph-color;
                        border-color: #eaedff;
                        font-size: 15.5px;
                        padding: {
                            left: 20px;
                            right: 20px;
                            top: 15px;
                            bottom: 13px;
                        }
                        &.product-name {
                            a {
                                color: $paragraph-color;
                                display: inline-block;
                                font: {
                                    weight: 500;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                        &.order-subtotal, &.order-shipping , &.total-price {
                            span {
                                color: $black-color;
                                font-weight: 600;
                            }
                        }
                        &.shipping-price, &.order-subtotal-price, &.product-subtotal {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .payment-box {
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        margin-top: 30px;
        padding: 30px;
        
        .payment-method {
            p {
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        display: none;
                    }
                }
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        + label {
                            padding-left: 27px;
                            cursor: pointer;
                            display: block;
                            color: $black-color;
                            position: relative;
                            margin-bottom: 8px;
                            font: {
                                weight: 600;
                            }
                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 3px;
                                width: 18px;
                                height: 18px;
                                border: 1px solid #dddddd;
                                border-radius: 50%;
                                background: $white-color;
                            }
                            &::after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                background: $main-color;
                                position: absolute;
                                top: 6px;
                                left: 3px;
                                border-radius: 50%;
                                transition: $transition;
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:not(:checked) {
                        + label {
                            &::after {
                                opacity: 0;
                                visibility: hidden;
                                transform: scale(0);
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:checked {
                        + label {
                            &::after {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
        .default-btn {
            &.order-btn {
                display: block;
                margin-top: 25px;
                box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2);
            }
        }
    }
}

.payment-method {
    p {
        margin-bottom: 10px;
    }
    label {
        margin: 0 0 0 5px;
    } 
}

.default-btn {
    &.order-btn {
        margin-top: 10px;
        &.btn-disabled {
            opacity: .4;
        }
    }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion {
    .accordion {
        list-style-type: none;
        padding-left: 0;
        border: none;
        border-radius: 0;
        margin: {
            bottom: 0;
        }
        .accordion-item {
            display: block;
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
            background: $white-color;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
            &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8) {
                .accordion-title {
                    i {
                        background: $optional-color;
                    }
                }
            }
        }
        .accordion-title {
            padding: 15px 20px 10px 51px;
            color: $black-color;
            position: relative;
            border-bottom: 1px solid transparent;
            margin-bottom: -1px;
            display: inline-block;
            font: {
                size: $font-size;
                weight: 700;
            }
            i {
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                text-align: center;
                height: 100%;
                background: $main-color;
                color: $white-color;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    font-size: 13px;
                }
            }
            &.active {
                border-bottom-color: #eeeeee;

                i {
                    &::before {
                        content: "\f068";
                    }
                }
            }
        }
        .accordion-content {
            position: relative;
            padding: 20px;
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
            font-size: 15px;
            border-top: 1px solid #f1f1f1;
            background-color: $white-color;
            &.show {
                display: block;
            }
        }
        .accordion__item {
            border: none;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            .accordion__button {
                box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
                background: $white-color;
                padding: 20px 25px 15px;
                color: #212529;
                font-size: 16px;
                font-weight: 800;
                cursor: pointer;

                &:focus {
                    outline: 0;
                }
            }
            .accordion__panel {
                padding: 0;
            }
        }
    }
}
.faq-area {
    .col-lg-6 {
        &:nth-child(2) {
            .faq-accordion {
                .accordion {
                    .accordion-item {
                        &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8) {
                            .accordion-title {
                                i {
                                    background: $main-color;
                                }
                            }
                        }
                    }
                    .accordion-title {
                        i {
                            background: $optional-color;
                        }
                    }
                }
            }
        }
    }
}
.faq-contact-form {
    padding-left: 30px;

    form {
        .form-control {
            background-color: #ffffff;
            border-color: #f2effc;
            box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
            height: 55px;
            border-radius: 5px;
            font-size: 15px;
            padding: 10px 20px;
            &:focus {
                border-color: $main-color;
                box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
            }
        }
        .row {
            margin: {
                left: -10px;
                right: -10px;
            }
            .col-lg-12, .col-lg-6 {
                padding: {
                    left: 10px;
                    right: 10px;
                }
            }
        }
        .form-group {
            margin-bottom: 20px;
        }
        textarea.form-control {
            height: auto;
            padding-top: 20px;
        }
        .default-btn {
            margin-top: 5px;
            box-shadow: 0 5px 28.5px 1.5px rgba(0, 0, 255, 0.2);
        }
        .help-block {
            ul {
                padding-left: 0;
                list-style-type: none;
                margin: {
                    top: 5px;
                    bottom: 0;
                }
                li {
                    color: red;
                }
            }
        }
        #msgSubmit {
            margin-bottom: 0;
            text-align: left !important;

            &.text-danger, &.text-success {
                margin-top: 8px;
                font: {
                    size: 23px;
                    weight: 700;
                }
            }
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-area {
    background-color: #f1f1f1;
    border-top: 1px solid #f2f2f2;
}
.contact-form {
    padding-left: 30px;

    form {
        .form-control {
            background-color: $white-color;
            border-color: #f2effc;
            box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
            height: 55px;
            border-radius: 5px;
            font: {
                size: 15px;
            }
            padding: 10px 20px;
            &:focus {
                border-color: $main-color;
                box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
            }
        }
        .form-group {
            margin-bottom: 20px;
        }
        .row {
            margin: {
                left: -10px;
                right: -10px;
            }
            .col-lg-12, .col-lg-6 {
                padding: {
                    left: 10px;
                    right: 10px;
                }
            }
        }
        textarea.form-control {
            height: auto;
            padding-top: 20px;
        }
        .default-btn {
            margin-top: 5px;
            box-shadow: 0 5px 28.5px 1.5px rgba(0, 0, 255, 0.2);
        }
        .help-block {
            ul {
                padding-left: 0;
                list-style-type: none;
                margin: {
                    top: 5px;
                    bottom: 0;
                }
                li {
                    color: red;
                }
            }
        }
        #msgSubmit {
            margin-bottom: 0;
            text-align: left !important;

            &.text-danger, &.text-success {
                margin-top: 8px;
                font: {
                    size: 23px;
                    weight: 700;
                }
            }
        }
    }
}
.contact-info-box {
    text-align: center;
    border-radius: 5px;
    transition: $transition;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 30px;
    margin-bottom: 30px;

    .icon {
        display: inline-block;
        width: 70px;
        height: 70px;
        line-height: 70px;
        background: #f5f5f5;
        border-radius: 50%;
        font-size: 30px;
        color: $main-color;
        transition: $transition;
        margin-bottom: 25px;
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 24px;
        }
    }
    p {
        margin-bottom: 0;

        a {
            display: inline-block;
            color: $paragraph-color;

            &:hover {
                color: $main-color;
            }
        }
    }
    &:hover {
        transform: translateY(-10px);

        .icon {
            background-color: $main-color;
            color: $white-color;
            border-color: $main-color;
        }
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    padding-top: 80px;
    background-color: $black-color;
}
.single-footer-widget {
    margin-bottom: 30px;

    h3 {
        margin-bottom: 35px;
        color: $white-color;
        position: relative;
        padding-bottom: 8px;
        border-bottom: 1px solid #4e5e6a;
        font: {
            size: 24px;
        }
        &::before {
            bottom: -1px;
            left: 0;
            width: 55px;
            height: 1px;
            background-color: $white-color;
            content: '';
            position: absolute;
        }
    }
    .footer-contact-info {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: $white-color;
            padding-left: 55px;
            position: relative;
            margin-bottom: 25px;
            font-size: 14.5px;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                font-size: 35px;
                color: $optional-color;
                position: absolute;
                left: 0;
                top: 0;
            }
            span {
                display: block;
                margin-bottom: 5px;
                text-transform: uppercase;
            }
            a {
                display: inline-block;
                color: $white-color;
                font: {
                    size: 22px;
                    weight: 700;
                }
                &:hover {
                    color: $optional-color;
                }
            }
            .social {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    padding-left: 0;
                    margin: {
                        right: 15px;
                        bottom: 0;
                    }
                    a {
                        display: inline-block;

                        i {
                            position: relative;
                            color: $white-color;
                            font-size: 17px;
                            transition: $transition;
                        }
                        &:hover {
                            transform: translateY(-2px);

                            i {
                                color: $optional-color;
                            }
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .footer-quick-links {
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            right: -15px;
            left: -15px;
            bottom: 0;
            top: -15px;
        }
        li {
            -ms-flex: 0 0 45%;
            flex: 0 0 45%;
            max-width: 45%;
            padding: {
                left: 15px;
                right: 15px;
                top: 15px;
            }
            a {
                display: inline-block;
                color: $white-color;

                &:hover {
                    color: $optional-color;
                }
            }
        }
    }
    .footer-instagram-post {
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            right: -5px;
            left: -5px;
            bottom: 0;
            top: -10px;
        }
        li {
            -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
            padding: {
                left: 5px;
                right: 5px;
                top: 10px;
            }
            a {
                display: block;

                &:hover {
                    opacity: .60;
                }
            }
        }
    }
}
.copyright-area {
    border-top: 1px solid #4e5e6a;
    margin-top: 50px;
    padding: {
        top: 25px;
        bottom: 25px;
    }
    p {
        color: $white-color;
        margin-bottom: 0;

        a {
            display: inline-block;
            color: $optional-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        text-align: right;

        li {
            display: inline-block;
            margin: 0 15px;
            color: $white-color;
            position: relative;

            a {
                display: inline-block;
                color: $white-color;

                &:hover {
                    color: $optional-color;
                }
            }
            &::before {
                content: '';
                position: absolute;
                right: -15px;
                top: 3px;
                width: 1px;
                height: 14px;
                background-color: $white-color;
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
}

/*================================================
Go Top CSS
=================================================*/
.scroll-to-top {
    i {
        position: fixed;
        cursor: pointer;
        bottom: 15px;
        right: 15px;
        background-color: $main-color;
        z-index: 4;
        width: 40px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        transition: .9s;
        color: $white-color;
        border-radius: 2px;
        font-size: 20px;
        &:hover, &:focus {
            color: $white-color;
            background-color: $optional-color;
        }
    }
}
.main-content {
    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    ul {
        padding-left: 17px;
        margin-top: 15px;
        li {
            line-height: 1.8;
        }
    }
}

// Form content
.form-content {
    background-color: #FAFBFD;
    max-width: 500px;
    margin: 100px auto;
    padding: 40px 30px;

    .form-header {
        text-align: center;
        margin-bottom: 20px;

        h3 {
            font-weight: bold;
        }
        p {
            margin: 0;
        }
    }

    .form-group {
        margin-bottom: 20px;
        label {
            margin-bottom: 3px;
        }
        &.form-check {
            label {
                font-size: 15px;
            }
        }
    }
 
    .default-btn {
        display: block;
        width: 100%;
    }

    .form-link {
        font-size: 15px;
        display: inline-block;
    }

    .form-footer {
        margin-top: 20px;
        text-align: center;
        p {
            margin: 0;
            a {
                margin: 0 3px;
                color: $main-color;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
.thank-you-area {
    text-align: center;
    h1 {
        margin-bottom: 20px;
    }
}

/*================================================
Start IT Startup Page CSS
=================================================*/
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.gray-bg {
    background-color: $gray-color;
}
.bg-f4f7fe {
    background-color: #f4f7fe;
}

// IT Banner
.it-banner {
    height: 100vh;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #f7f7f7;

    .container {
        &.mt-50 {
            margin-top: 50px;
        }
    }

    .banner-btn {
        margin-top: 30px;
        a {
            display: inline-flex;
        }
    }

    .video-btn {
        margin-left: 5px;
        line-height: 40px;
        cursor: pointer;
        display: inline-block;
        i {
            background-color: #ececff;
            height: 35px;
            width: 35px;
            display: inline-block;
            color: $main-color;
            text-align: center;
            line-height: 35px;
            border-radius: 50%;
            padding-left: 3px;
            margin-right: 8px;
            transition: $transition;
        }
        &:hover {
            i {
                background-color: $main-color;
                color: $white-color
            };
        }
    }
    .it-banner-image {
        text-align: center;

        .swiper-button-prev, .swiper-button-next {
            background-color: $main-color;
            color: #fff;
            width: 30px;
            height: 30px;
            transition: $transition;
            border-radius: 4px;

            &::after {
                font-size: 20px;
            }
        }
    }
}

.shape-img11 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1;
    img {
        max-width: 100px;
    }
}
.shape-img12 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

/* Animate banner image */
.animate-image {
    position: relative;
}
.animate-image {
    img {
        display: block !important;
        -webkit-animation: border-transform 10s linear infinite alternate forwards;
        animation: border-transform 10s linear infinite alternate forwards;
        margin: auto;
    }
}
@-webkit-keyframes border-transform {
    0%, 100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }
    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }
    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }
    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }
    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }
    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }
    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}

@keyframes border-transform {
    0%, 100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }
    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }
    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }
    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }
    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }
    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }
    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}

// Service Card
.service-card-one {
    margin-bottom: 30px;
    border-radius: 10px;
    transition: $transition;
    .icon {
        position: relative;
        i {
            background-color: #ececff;
            height: 65px;
            width: 65px;
            text-align: center;
            line-height: 65px;
            border-radius: 50%;
            font-size: 30px;
            color: $main-color;
            display: inline-block;
            margin-bottom: 25px;
        }
        .check-icon {
            position: absolute;
            background: transparent;
            width: auto;
            height: auto;
            line-height: 1;
            margin: 0px 0px 20px -20px;
            font-size: 18px;
        }
    }

    h3 {
        font-size: 20px;
    }
    p {
        margin-bottom: -6px;
    }

    &.bg-white {
        padding: 30px;
    }
    &.center {
        text-align: center;
    }
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.08);
    }

    &.gray-bg {
        padding: 30px 40px;

        &:hover {
            background-color: $main-color;
            .icon {
                .check-icon {
                    display: none;
                }
            }
            h3 {
                color: $white-color;
                a {
                    color: $white-color;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            p {
                color: $white-color;
            }
        }
    }
}

// IT Overview
.it-overview {
    .overview-content {
        .content {
            .features-list {
                li {
                    span {
                        i {
                            color: $main-color;
                            width: auto;
                            height: auto;
                            background-color: transparent;
                            line-height: 1;
                            font-size: 20px;
                            left: 12px;
                            top: 11px;
                        }
                        &:hover {
                            i {
                                background-color: transparent;
                                color: $white-color;
                            }
                        }
                    }
                }
            }

            .rm-btn {
                margin-top: 25px;
            }

            .features-text {
                margin-top: 20px;

                h4 {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

// Work Card 
.works-area {
    .more-work {
        margin-top: 20px;
        text-align: center;
    }
}
.work-card {
    margin-bottom: 30px;
    position: relative;
    transition: $transition;

    &::before {
        content: '';
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        width: 100%;
        height: 90%;
        left: 0;
        bottom:0;
        position: absolute;
        border-radius: 8px;
        transition: $transition;
        z-index: 1;
    }

    &:hover::before {
        background-color: rgba(0, 0, 0, 0.80);
    }

    img {
        border-radius: 8px;
        width: 100%;
    }

    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 30px;
        transition: $transition;
        z-index: 5;
        
        span {
            display: block;
            margin-bottom: 10px;
            font-size: 15px;
            a {
                color: $white-color;
            }
        }
        h3 {
            font-size: 22px;
            line-height: 1.4;
            margin-bottom: 20px;
        }
        .work-btn {
            background-color: $white-color;
            color: $main-color;
            padding: 10px 20px 5px;
            border-radius: 30px;
            font-size: 15px;
            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
        }

        a {
            color: $white-color;
        }
    }

    &:hover {
        &::before {
            height: 100%;
            background-image: linear-gradient(to bottom, rgba(rgb(0, 0, 255), 0), rgba(rgb(0, 0, 255), 1));
        }
        .content {
            bottom: 20px;
        }
    }
}

// Team slider
.team-slider {
    .single-team-box {
        box-shadow: none;
        .content {
            border-bottom: 1px solid #f7f7f7;
            border-left: 1px solid #f7f7f7;
            border-right: 1px solid #f7f7f7;
        }

        &:hover {
            .content {
                border-bottom: 1px solid $main-color;
                border-left: 1px solid $main-color;
                border-right: 1px solid $main-color;
            }
        }
    }
    .swiper-button-prev, .swiper-button-next {
        top: 10px;
        margin-top: 0;
        width: 30px;
        background: #fff;
        height: 30px;
        color: $black-color;

        &:after {
            font-size: 20px;
        }
  
        &:hover {
            background-color: $main-color;
            color: #fff;
        }
    }
}

// Single pricing table
.pricing-area-two {
    position: relative;
    z-index: 0;
    overflow: hidden;
}
.single-pricing-table {
    margin-bottom: 30px;
    background-color: $white-color;
    padding-bottom: 30px;
    border-radius: 5px;
    transition: $transition;

    .pricing-header {
        padding: 20px 40px;

        .icon {
            background-color: #ececff;
            width: 120px;
            height: 120px;
            text-align: center;
            line-height: 120px;
            border-radius: 50%;
            margin: 0 auto 25px;
            img {
                max-width: 70px;
            }
        }
        h3 {
            margin-bottom: -5px;
            font-size: 20px;
        }
        p {
            margin: 10px 0 -10px;
            font-size: 15px;
        }
    }
    .price {
        color: $black-color;
        transition: $transition;
        padding: 10px 40px 0;
        border-top: 2px dashed #f4f5fe;
        border-bottom: 2px dashed #f4f5fe;
        font-size: 40px;
        font-weight: 900;

        sup {
            top: -18px;
            font-size: 18px;
            font-weight: 500;
        }

        sub {
            bottom: 2px;
            color: #4a6f8a;
            padding-right: 5px;
            font-size: 14px;
            font-weight: 500;
        }
    }
    
    .pricing-features {
        margin: 0;
        padding: 30px 40px;
        list-style-type: none;
        li {
            margin-bottom: 10px;
            position: relative;
            color: #444683;
            padding-left: 25px;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                color: $main-color;
                position: absolute;
                left: 0;
                top: 2px;
            }
            span {
                display: inline-block;
                color: #b7bad2;
                font-size: 20px;
                position: absolute;
                top: 1px;
                margin-left: 3px;
            }
        }
    }
    .btn-box {
        padding: 0 40px;
    }
    &:hover {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        transform: translateY(-10px);
    }

    &.center {
        text-align: center;

        .pricing-features {
            text-align: left;
            li {
                padding-left: 0;
                padding-right: 25px;
                i {
                    left: auto;
                    right: 0;
                }
            }
        }
        .default-btn {
            display: block;
            width: 100%;
        }
    }
}

// Lets work
.lets-work {
    .sub-title {
        color: $white-color;
        display: block;
        margin-bottom: 10px;
    }
    h2 {
        color: $white-color;
        font-size: 60px;
        font-weight: 900;
    }
    .contact-btn {
        text-align: right;

        .default-btn {
            box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.25);
            
            span {
                background-color: $optional-color;
                width: 100%;
                height: 100%;
                left: 0 !important;
                top: 0 !important;
                transform: translate(0%, 0%);
            }

            &:hover {
                span {
                    background-color: #000;
                }
            }
        }
    }
}

// Feedback area two
.feedback-area-two {
    .section-title {
        margin-bottom: 40px;
    }

    .feedback-slides {
        .single-feedback-item {
            margin-top: 15px;

            &.border {
                border: 1px solid #eee;
                border-radius: 10px;
            }
        }
    }
}
/*================================================
End IT Startup Page CSS
=================================================*/

/*================================================
SEO Startup Page CSS
=================================================*/
// SEO Banner
.seo-banner-slider {
    img {
        width: auto !important;
        display: inline-block !important;
    }

    .swiper-button-prev, .swiper-button-next {
        width: 35px;
        background-color: $main-color;
        color: $white-color;
        height: 35px;
   
        &:after {
            font-size: 20px;
        }
        &:hover {
            background-color: $black-color;
            color: $white-color;
        }
    }
    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 0;
    }
}
.seo-banner {
    height: 100vh;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #f7f7f7;

    .container {
        &.mt-50 {
            margin-top: 50px;
        }
    }

    .banner-content {
        h1 {
            font-size: 50px;
        }
    }

    .banner-btn {
        margin-top: 30px;
        a {
            display: inline-flex;
        }
    }

    .video-btn {
        margin-left: 5px;
        line-height: 40px;
        cursor: pointer;
        display: inline-block;

        i {
            background-color: #ececff;
            height: 35px;
            width: 35px;
            display: inline-block;
            color: $main-color;
            text-align: center;
            line-height: 35px;
            border-radius: 50%;
            padding-left: 5px;
            margin-right: 8px;
            transition: $transition;
        }
        &:hover {
            i {
                background-color: $main-color;
                color: $white-color
            };
        }
    }
    .seo-banner-image {
        text-align: center;

        &.animate-tb {
            animation: moveBounce 5s linear infinite;
        }
    }
}
.shape-img13 {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}
.shape-img14 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

// SEO featured services
.rounded-10 {
    border-radius: 10px;
}
.seo-featured-services {
    .col-lg-7 {
        .row {
            .col-lg-6 {
                &:nth-child(2) {
                    margin-top: 30px;
                }
                &:nth-child(3) {
                    margin-top: -30px;
                }
                &:nth-child(5) {
                    margin-top: -30px;
                }
            }
        }
    }
}

// About area two
.about-area-two {
    .section-title {
        margin-bottom: 30px;
        max-width: 525px;
        margin-left: 0;
        width: 100%;
        h2 {
            max-width: 100%;
            margin-bottom: 0;
        }
    }
    .about-image {
        position: relative;
    }
    .solution-video {
        text-align: center;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
        margin: 0;

        .video-btn {
            &:hover {
                &::after, &::before {
                    border: 1px solid transparent;
                }
            }
        }
    }
    .about-content {
        padding-left: 20px;
    }
    .about-text {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        h4 {
            font-size: 20px;
        }
    }
}

// Work Slider
.works-area  {
    .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
        max-width: 1920px;
    }
}
.work-slider {
    .work-card {
        margin-bottom: 0;
    }
    .swiper-button-prev, .swiper-button-next {
        top: 10px;
        width: 30px;
        background: #fff;
        height: 30px;
        color: $black-color;
        margin-top: 0;

        &::after {
            font-size: 20px;
        }

        &:hover {
            background-color: $main-color;
            color: #fff;
        }
    }
}

// Choose area two
.choose-area-two {
    .section-title {
        margin-bottom: 30px;
        max-width: 100%;
        h2 {
            font-size: 36px;
            max-width: 6100%;
            width: 100%;
        }
    }

    .choose-text {
        position: relative;
        padding-left: 40px;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }
        i {
            position: absolute;
            font-size: 30px;
            color: $main-color;
            left: 0;
            top: 0;
        }
        h4 {
            font-size: 20px;
        }
    }
}

.features-area {
    .tabs_item {
        &.bg-white {
            padding: 20px;
            border: 1px solid #f7f7f7;
        }
    }
}

.red {
    color: red !important;
}

.seo-analysis-section {
    .section-title {
        max-width: 100%;
        margin-bottom: 30px;
    }
    .analysis-img {
        text-align: center;
    }
}

.analysis-form {
    .form-group {
        margin-bottom: 25px;
    }
    .form-control {
        background-color: #f6f6f6;
        border-radius: 5px;
        padding-left: 20px;
        font-size: 15px;
        color: #9d9fad;
        line-height: 30px;
        height: 56px
    }
    button.default-btn {
        margin-top: 10px;
    }
}

/*================================================
End SEO Startup Page CSS
=================================================*/

/*================================================
SaaS Startup Page CSS
=================================================*/
.default-btn-two {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $white-color;
    text-transform: uppercase;
    background-color: $optional-color;
    transition: $transition;
    border-radius: 5px;
    font: {
        weight: 600;
        size: 15px;
    }
    padding: {
        left: 35px;
        right: 35px;
        top: 17px;
        bottom: 13px;
    }
    span {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: $main-color;
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
    }
    &:hover, &:focus {
        color: $white-color;
        box-shadow: 0px 10px 13px -6px rgba(0,0,0,.25);
        transform: translateY(-4px);

        span {
            width: 225%;
            height: 562.5px;
        }
    }
} 

.saas-banner-two {
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: #f1ece4;
    z-index: 0;
 
    .container {
        &.mt-50 {
            margin-top: 50px;
        }
    }

    .banner-btn {
        margin-top: 30px;
        a {
            display: inline-flex;
        }
    }
    .saas-banner-image {
        text-align: center;
        position: relative;

        img {
            border-radius: 10px;
        }

        &.animate-tb {
            animation: moveBounce 5s linear infinite;
        }

        .solution-video {
            text-align: center;
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            transform: translateY(-50%);
            margin: 0;

            .video-btn {
                background-color: $main-color;
                color: $white-color;
                cursor: pointer;
                display: inline-block;

                &:hover {
                    &::after, &::before {
                        border: 1px solid transparent;
                    }
                }

                &::after, &::before {
                    border: 1px solid $main-color;
                }
            }
        }
    }
}

// Service card one
.service-card-one {
    .icon {
        .number {
            background-color: $main-color;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            color: #fff;
            display: inline-block;
            font-size: 10px;
            line-height: 22px;
            position: absolute;
            margin: 50px 0 0 -25px;
        }
    }
}

// Fun facts two
.bg-f2f2f7 {
    background-color: #f2f2f7;
}
.fun-fact-card {
    margin-bottom: 30px;
    text-align: center;
    background: #fff;
    padding: 30px 10px;
    border-radius: 10px;
    transition: $transition;

    i {
        background-color: #ececff;
        color: $main-color;
        font-size: 35px;
        width: 65px;
        height: 65px;
        line-height: 65px;
        border-radius: 50%;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 40px;
        font-weight: 800;
    }

    &:hover {
        transform: translateY(-5px);
    }
}
/*================================================
End SEO Startup Page CSS
=================================================*/

/*================================================
AI & Machine Learning Page CSS
=================================================*/
@media only screen and (min-width: 992px) {
    .navbar-area-two {
        .main-nav {
            .navbar {
                .navbar-nav {
                    .nav-item {
                        a {
                            color: $white-color;
                            &:hover {
                                color: $optional-color;
                            }
                        }
                    }
                }

                .others-options {
                    .cart-items {
                        color: $white-color;
                        a {
                            color: $white-color;
                            &:hover {
                                color: $white-color;
                            }
                        }
                    }
                    .option-item {
                        .search-btn {
                            color: $white-color;
                        }
                    }
                    .burger-menu {
                        span {
                            background: $white-color;
                        }
                    }
                }
            }
        }

        &.is-sticky {
            .main-nav {
                .navbar {
                    .navbar-nav {
                        .nav-item {
                            a {
                                color: #5b5b98;
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                    }
        
                    .others-options {
                        .cart-items {
                            color: #5b5b98;
                            a {
                                color: #5b5b98;
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                        .option-item {
                            .search-btn {
                                color: #5b5b98;
                            }
                        }
                        .burger-menu {
                            span {
                                background: #5b5b98;
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar-area-two {
        .navbar-brand {
            .black-logo {
                display: none;
            }
        }

        &.is-sticky { 
            .black-logo {
                display: block;
            }
            .white-logo {
                display: none;
            }
        }
    }
}

.machine-learning-banner {
    height: 100vh;
    position: relative;
    overflow: hidden;
    z-index: 0;
    background-size: cover;
    background-position: center center;
 
    .container {
        &.mt-80 {
            margin-top: 80px;
        }
    }

    .banner-content {
        h1 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
    }

    .banner-btn {
        margin-top: 30px;
        a {
            display: inline-flex;
        }
    }
    .ml-video {
        position: relative;
        text-align: center;
    }
    .solution-video {
        text-align: center;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
        margin: 0;

        .video-btn {
            background-color: $white-color;
            color: $main-color;
            cursor: pointer;
            display: inline-block;

            &:hover {
                background-color: $optional-color;
                color: $white-color;
                &::after, &::before {
                    border: 1px solid transparent;
                }
            }

            &::after, &::before {
                border: 1px solid $white-color;
            }
        }
    }
}
.machine-learning-slider {
    .swiper-button-prev, .swiper-button-next {
        background-color: $white-color;
        width: 40px;
        height: 40px; 
        color: $main-color; 

        &::after {
            font-size: 20px;
        }

        &:hover {
            background-color: $main-color;
            color: $white-color;
        }
    }
    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 0;
    }
}

// Featured solution card
.featured-solution-card {
    box-shadow: 0px 5px 20px 0px rgba(105,146,234,.14);
    transition: $transition;
    padding: 30px 25px;
    margin-bottom: 30px;

    i {
        color: $main-color;
        font-size: 35px;
        display: inline-block;
        margin-bottom: 20px;
    }
    h3 {
        font-size: 19px;
        line-height: 1.3;
        a {
            color: $black-color;
            &:hover {
                color: $main-color;
            }
        }
    }
    p {
        margin: 0;
        font-size: 15px;
    }
    .read-more {
        color: $main-color;
        font-size: 14.5px;
        margin-top: 15px;
        position: relative;
        font-weight: 600;

        &::after {
            content: '';
            background-color: $main-color;
            height: 1px;
            width: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: $transition;
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }
    }
}

// ML Service
.ml-services-area {
    .section-title {
        max-width: 100%;
        h2 {
            max-width: 100%;
        }
        p {
            max-width: 600px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.ml-service {
    margin-bottom: 30px;
    background: #fff;
    text-align: center;
    padding: 30px;
    overflow: hidden;
    position: relative;
    transition: $transition;
  
    .image {
        margin-bottom: 30px;
    }
    h3 {
        font-size: 22px;
        margin-bottom: 10px;
        a {
            color: $black-color;
            &:hover {
                color: $main-color;
            }
        }
    }
    p {
        margin: 0;
        font-size: 15px;
    }
    .read-more {
        color: $main-color;
        font-size: 15px;
        margin-top: 15px;
        position: relative;
        font-weight: 600;

        &::after {
            content: '';
            background-color: $main-color;
            height: 1px;
            width: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: $transition;
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }
    }

    &::before {
        content: '';
        background-color: $main-color;
        position: absolute;
        top: -100%;
        left: -100%;
        width: 90px;
        height: 50px;
        transform: rotate(135deg);
        transition: $transition;
    }
    &:hover {
        transition: $transition;
        &::before {
            top: -32px;
            left: -34px;
        }
    }
}

// Free trial area
.free-trial-area {
    .subscribe-content {
        .sub-title {
            color: $main-color;
        }
        h2 {
            color: $black-color;
        }
        .help-text {
            font-size: 15px;
            margin: 15px 0 0;
            font-style: italic;
        }
        .validation-danger {
            color: red !important;
            font-size: 15px;
        }
    }
}
/*================================================
End AI & Machine Learning Page CSS
=================================================*/


/*================================================
Portfolio Agency Page CSS
=================================================*/
.portfolio-agency-banner {
    height: 100vh;
    position: relative;
    overflow: hidden;
    z-index: 0;
    background-size: cover;
    background-position: center center;
    background-image: url(../img/portfolio-agency/pa-slider1.jpg);

    &::before {
        content: '';
        background-color: rgba(0, 0, 0, 0.70);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
 
    .container {
        &.mt-80 {
            margin-top: 80px;
        }
    }

    .banner-content {
        position: relative;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        h1 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
    }

    .banner-btn {
        margin-top: 30px;
        a {
            display: inline-flex;
        }
    }
}
 
/*================================================
End Portfolio Agency Learning Page CSS
=================================================*/

.team-area {
    .max-w-1700 {
        max-width: 1700px;
        padding-left: 30px;
        padding-right: 30px;
    }
} 
.pt-0 {
    .ptb-100 {
        padding-top: 0 !important;
    }
}


/* Start "Cryptocurrency Demo CSS, "Cyber Security Demo CSS & Big Data Solution Demo CSS" */
.section-title {
    &.with-linear-gradient-text {
        max-width: 725px;

        h2 {
            max-width: 725px;

            span {
                background: linear-gradient(89.02deg, #3065BA 68.24%, #8459EA 97.09%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }
    }
    &.with-line-text {
        max-width: 725px;

        .sub-title {
            color: $white-color;
        }
        h2 {
            max-width: 725px;
            color: $white-color;

            span {
                position: relative;
                z-index: 1;
                color: $white-color;

                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 12px;
                    background: linear-gradient(90deg, #D3064E -0.61%, #FE5B67 100%);
                    height: 5px;
                    width: 100%;
                    z-index: -1;
                }
            }
        }
    }
    &.with-line-text-with-white-color {
        max-width: 725px;
        h2 {
            max-width: 725px;
            span {
                position: relative;
                z-index: 1;

                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 12px;
                    background: linear-gradient(90deg, #D3064E -0.61%, #FE5B67 100%);
                    height: 5px;
                    width: 100%;
                    z-index: -1;
                }
            }
        }
    }
}

.background-with-black-color {
    background-color: #1F1F22;
}

/*================================================
Topbar Wrap Area CSS
=================================================*/
.topbar-wrap-area {
    background-color: $main-color;
    padding: {
        top: 15px;
        bottom: 15px;
    };
    .container-fluid {
        padding: {
            left: 50px;
            right: 50px;
        };
    }
}
.topbar-social-links {
    padding: 0;
    margin-bottom: 0;
    line-height: 1;

    li {
        list-style-type: none;
        display: inline-block;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
        a {
            line-height: 1;        

            i {
                font-size: 20px;
                color: $white-color;
                transition: $transition;

                &:hover {
                    color: $optional-color;
                    transform: translateY(-2px);
                }
            }
        }
    }
}
.topbar-action-list {
    padding: 0;
    margin-bottom: 0;
    text-align: end;

    li {
        list-style-type: none;
        display: inline-block;
        margin-right: 30px;
        color: $white-color;
        position: relative;
        padding-left: 22px;

        &::before {
            content: "";
            position: absolute;
            top: 1px;
            right: -20px;
            width: 1px;
            height: 20px;
            border: 2px solid rgba(255, 255, 255, 0.18);
        }
        &:last-child {
            margin-right: 0;

            &::before {
                display: none;
            }
        }
        i {
            position: absolute;
            left: 0;
            top: 2px;
        }
        a {
            color: $white-color;

            &:hover {
                color: $optional-color;
            }
        }
    }
}

/*================================================
Value Trade Area CSS
=================================================*/
.value-trade-area {
    background-color: #F9F9F9;
    padding: {
        top: 10px;
        bottom: 10px;
    };
    .container-fluid {
        padding: {
            left: 30px;
            right: 30px;
        };
    }
}
.value-trade-card {
    position: relative;
    padding: 16.8px 15px 15px 45px;
    background-color: $white-color;
    box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);
    border-radius: 5px;

    img {
        display: inline-block;
        width: auto !important;
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
    p {
        font-size: 15.5px;
        font-weight: 400;
        margin-bottom: 0;
        font-weight: 400;
        color: $paragraph-color;

        b {
            font-weight: 600;
            color: $black-color;
        }
        span {
            font-weight: 600;
            color: #76BAAF;
            position: relative;
            margin-left: 15px;
            padding-left: 20px;
            
            i {
                position: absolute;
                left: 0;
                top: 0;
                font-size: 18px;
            }
        }
        .color-two {
            color: #FF6C6C;

            i {
                color: #FF6C6C;
            }
        }
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area-with-position-relative {
    position: relative;
    background-color: $white-color;
    box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);
 
    .main-nav {
        .container-fluid {
            padding: {
                left: 50px;
                right: 50px;
            };
        }
    }
    &.with-black-color {
        background-color: #1F1F22;
        box-shadow: unset;

        &.is-sticky {
            background-color: #1F1F22 !important;
            box-shadow: 0 0 30px #000 !important;
        }
        .main-nav {
            .navbar {
                .navbar-nav {
                    .nav-item {
                        a {
                            color: $white-color;

                            &:hover, &:focus, &.active {
                                color: $main-color;
                            }
                        }
                        &:hover, &:focus, &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            li {
                                a {
                                    color: #696997;
                                    
                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                            }
                        }
                    }
                }
                .others-options {
                    .cart-items {
                        a {
                            color: $white-color;
                        
                            &:hover {
                                color: $main-color;
                            }
                        }
                    }
                    .option-item {
                        color: $white-color;

                        .search-btn {
                            color: $white-color;
                            
                            &:hover {
                                color: $main-color;
                            }
                        }
                        .close-btn {
                            color: $white-color;
                            
                            &:hover {
                                color: $main-color;
                            }
                        }
                    }
                    .burger-menu {
                        span {
                            color: $white-color;
                            background-color: $white-color;
                        }
                        &:hover {
                            span {
                                background: $main-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Cryptocurrency Banner Area CSS
=================================================*/
.cryptocurrency-banner-section {
    background: #F1F9F9;
    padding-top: 100px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .container-fluid {
        padding: {
            left: 50px;
            right: 0;
        };
    }
    &::before {
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        height: 100%;
        width: 545px;
        background: #D3E3E7;
        z-index: -1;
    }
}
.cryptocurrency-banner-content {
    max-width: 620px;
    position: relative;
    top: -50px;

    .sub-title {
        color: #2B6DEF;
        font-size: $font-size;
        font-weight: 500;
        display: block;
        margin-bottom: 15px;
    }
    h1 {
        font-size: 66px;
        font-weight: 800;
        margin-bottom: 15px;

        span {
            background: linear-gradient(89.36deg, #2C66B8 0.07%, #8859EC 73.8%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
    p {
        margin-bottom: 0;
    }
    .banner-btn {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 0;
        };
        li {
            display: inline-flex;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
            .video-btn {
                margin-left: 5px;
                font-size: 15.5px;
                font-weight: 600;
                color: $black-color;
                cursor: pointer;

                i {
                    background: rgba(47, 143, 232, 0.07);
                    height: 45px;
                    width: 45px;
                    display: inline-block;
                    color: $main-color;
                    text-align: center;
                    line-height: 45px;
                    border-radius: 50%;
                    padding-left: 5px;
                    margin-right: 8px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color
                    };
                }
            }
        }
    }
}
.cryptocurrency-banner-image {
    position: relative;
    z-index: 1;

    .cart1 {
        position: absolute;
        bottom: 12%;
        left: -230px;
        bottom: 10%;
        transform: translateY(-12%);
        z-index: -1;
        animation: moveLeftBounce 5s linear infinite;
    }
    .cart2 {
        position: absolute;
        top: 5%;
        right: 20%;
        transform: translateY(-5%) translateX(-20%);
        z-index: -1;
        animation: moveLeftBounce 8s linear infinite;
    }
    .graph {
        position: absolute;
        left: -70px;
        top: 38%;
        transform: translateY(-38%);
        z-index: -11;
        animation: moveLeftBounce 8s linear infinite;
    }
}
.cryptocurrency-shape-1 {
    position: absolute;
    left: 10%;
    top: -20px;
    transform: translateX(-10%);
    z-index: -1;
    animation: moveLeftBounce 5s linear infinite;
}
.cryptocurrency-shape-2 {
    position: absolute;
    left: 40%;
    top: 100px;
    transform: translateX(-40%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 20s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.cryptocurrency-shape-3 {
    position: absolute;
    bottom: 15%;
    left: 2%;
    transform: translateY(-15%) translateX(-2%);
    z-index: -1;
    animation: moveBounce 5s linear infinite;
}
.cryptocurrency-shape-4 {
    position: absolute;
    bottom: 45%;
    right: 5%;
    z-index: -1;
    transform: translateY(-45%) translateX(-5%);

    img {
        animation-name: rotateMe;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

/*================================================
Stats Area CSS
=================================================*/
.stats-fun-fact-box {
    margin-bottom: 30px;
    transition: $transition;

    .stats-image {
        margin-bottom: 28px;
    }
    h3 {
        font-size: 30px;
        margin-bottom: 0;
    }
    span {
        font-size: $font-size;
        font-weight: bold;
        color: $paragraph-color;
        display: inline-block;
        margin-top: 10px;
    }
    &:hover {
        transform: translateY(-5px);
    }
}

/*================================================
Crypto About Area CSS
=================================================*/
.crypto-about-content {
    padding-left: 25px;

    .sub-title {
        color: $main-color;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        line-height: 1.4;

        span {
            background: linear-gradient(89.21deg, #2F66B9 21.64%, #8659EB 71.02%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
    p {
        margin-bottom: 0;
    }
    .list {
        padding: 0;
        margin: {
            top: 20px;
            bottom: 0;
        };
        li {
            list-style-type: none;
            font-size: 16.5px;
            font-weight: bold;
            color: $paragraph-color;
            margin-bottom: 15px;
            position: relative;
            padding-left: 25px;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                position: absolute;
                left: 0;
                top: 2px;
                color: #76BAAF;
                font-size: 16.5px;
            }
            span {
                color: #29A9E1;
                border-bottom: 1px solid #29A9E1;
            }
        }
    }
    .about-btn {
        margin-top: 28px;
    }
}

/*================================================
Earning Platform Area CSS
=================================================*/
.earning-platform-area {
    background: #F4FAFD;

    .section-title {
        max-width: 725px;

        h2 {
            max-width: 725px;
        }
    }
}
.earning-platform-card {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
    padding: 30px;
    border-radius: 5px;
    transition: $transition;

    .earning-image {
        display: inline-block;
        height: 100px;
        width: 100px;
        line-height: 100px;
        background-color: $white-color;
        text-align: center;
        border-radius: 50px;
        box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
        margin-bottom: 28px;
        transition: $transition;
    }
    h3 {
        font-size: 22px;
        margin-bottom: 14px;
    }
    &:hover{
        transform: translateY(-5px);
    }
}

/*================================================
Crypto Get Strated Area CSS
=================================================*/
.crypto-get-strated-area {
    background: linear-gradient(to right, #3565bd, #4365cb, #5663d7, #6b60e1, #835ae9);
    padding-top: 100px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.crypto-get-strated-content {
    padding-left: 25px;
    position: relative;
    top: -50px;

    .content {
        max-width: 650px;

        span {
            color: $white-color;
            font-size: 15px;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 15px;
        }
        h3 {
            font-size: 40px;
            margin-bottom: 0;
            line-height: 1.4;
            color: $white-color;
        }
    }
    .crypto-get-strated-card {
        margin-top: 30px;
        margin-left: 5px;

        .get-image {
            margin-bottom: 30px;
        }
        h3 {
            font-size: 24px;
            margin-bottom: 12px;
            color: $white-color;
        }
        p {
            color: $white-color;
            margin-bottom: 0;
        }
    }
}
.crypto-get-strated-shape {
    position: absolute;
    top: 10%;
    right: 10%;
    transform: translateY(-10%) translateX(-10%);
    animation: moveBounce 5s linear infinite;
    z-index: -1;
}

/*================================================
Key Features Area CSS
=================================================*/
.key-features-area {
    background: #F4FAFD;
}
.key-features-card {
    margin-bottom: 30px;

    .key-content {
        border-radius: 5px;
        position: relative;
        background-color: $white-color;
        box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
        padding: 30px 30px 30px 125px;
        transition: $transition;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
        &.right-gap {
            position: relative;
            right: 30px;
        }
        &.left-gap {
            position: relative;
            left: 30px;
        }
        .icon-image {
            display: inline-block;
            height: 80px;
            width: 80px;
            line-height: 80px;
            background-color: $white-color;
            text-align: center;
            border-radius: 50px;
            box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
            transition: $transition;
            position: absolute;
            left: 25px;
            top: 30px;

            img {
                max-width: 40px;
            }
        }
        h3 {
            font-size: 22px;
            margin-bottom: 12px;
        }
        p {
            margin-bottom: 0;
        }
        &:hover {
            transform: translateY(-5px);
        }
    }
}

/*================================================
Mining Area CSS
=================================================*/
.mining-content {
    padding-left: 50px;

    .sub-title {
        color: $main-color;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        line-height: 1.4;

        span {
            background: linear-gradient(89.21deg, #2F66B9 21.64%, #8659EB 71.02%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
    p {
        margin-bottom: 0;
    }
    .mining-btn {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 0;
        };
        li {
            display: inline-flex;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
            .video-btn {
                margin-left: 5px;
                font-size: 15.5px;
                font-weight: 600;
                color: $black-color;
                cursor: pointer;

                i {
                    background: rgba(47, 143, 232, 0.07);
                    height: 45px;
                    width: 45px;
                    display: inline-block;
                    color: $main-color;
                    text-align: center;
                    line-height: 45px;
                    border-radius: 50%;
                    padding-left: 5px;
                    margin-right: 8px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color
                    };
                }
            }
        }
    }
}

/*================================================
Trade Over Area CSS
=================================================*/
.trade-over-inner-box {
    background-color: $white-color;
    box-shadow: 0px 3px 20px rgba(36, 157, 210, 0.12);
    border-radius: 5px;
    padding: {
        left: 50px;
        right: 50px;
    };
}
.trade-over-card {
    margin-bottom: 30px;
    position: relative;
    padding-left: 65px;

    .over-image {
        position: absolute;
        left: 0;
        top: 0;
    }
    p {
        font-size: 15.5px;
        font-weight: 400;
        margin-bottom: 20px;
        font-weight: 400;
        color: $paragraph-color;

        b {
            font-weight: 600;
            color: $black-color;
        }
        span {
            font-weight: 500;
            color: $white-color;
            display: inline-block;
            padding: 8px 10px 5px;
            background: #76BAAF;
            box-shadow: -2px 6px 20px rgba(255, 109, 52, 0.07);
            border-radius: 5px;
            font-size: 14.5px;
            margin-left: 10px;
            line-height: 1;
        }
        .color-two {
            background-color: #FF6C6C;
        }
    }
    h3 {
        font-size: 30px;
        margin-bottom: 8px;
    }
    span {
        color: $paragraph-color;
    }
    .over-shape {
        position: absolute;
        right: 0;
        top: 0;
    }
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.why-choose-us-area {
    background-color: #F4FAFD;
}
.why-choose-us-content {
    max-width: 550px;

    .sub-title {
        color: $main-color;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 12px;
        line-height: 1.4;

        span {
            background: linear-gradient(89.21deg, #2F66B9 21.64%, #8659EB 71.02%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
    p {
        margin-bottom: 0;
    }
    .choose-list {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 0;
        };
        li {
            list-style-type: none;
            background-color: $white-color;
            box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
            position: relative;
            margin-bottom: 20px;
            padding: 15px 15px 15px 40px;
            border-radius: 5px;
            font-size: 16.5px;
            font-weight: bold;
            color: $paragraph-color;
            transition: $transition;

            &:last-child {
                margin-bottom: 0;
            }
            &::before {
                position: absolute;
                content: "";
                display: inline-block;
                height: 10px;
                width: 10px;
                background-color: #76BAAF;
                border-radius: 50px;
                left: 20px;
                top: 19.5px;
            }
            &:hover {
                transform: translateY(-2px);
            }
        }
    }
}
.why-choose-us-card {
    background-color: $white-color;
    border: 1px solid #EBD7D3;
    padding: 30px;
    border-radius: 5px;
    transition: $transition;

    .image {
        display: inline-block;
        height: 80px;
        width: 80px;
        line-height: 80px;
        background-color: $white-color;
        text-align: center;
        border-radius: 50px;
        box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
        margin-bottom: 28px;
        transition: $transition;
    }
    h3 {
        font-size: 22px;
        margin-bottom: 14px;
    }
    &:hover{
        transform: translateY(-5px);
        border: 1px solid $main-color;
    }
}
.choose-with-box-style {
    .col-lg-6 {
        &:nth-child(2) {
            .why-choose-us-card {
                margin-bottom: 25px;
            }
        }
    }
}

/*================================================
Unique Feedback Area CSS
=================================================*/
.unique-feedback-area {
    background-image: url(/images/cryptocurrency-home/unique-feedback-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .section-title {
        .sub-title {
            color: $white-color;
        }
        h2 {
            color: $white-color;
        }
    }
    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        height: 150px;
        width: 100%;
        background-color: #F4FAFD;
        z-index: -1;
    }
    &.wrap-with-black-color {
        background-image: unset;

        &::before {
            display: none;
        }
    }
    &.with-linear-gradient-color {
        background-image: unset;
        background: linear-gradient(86.42deg, #2E66B9 0%, #8859EC 101.05%);

        &::before {
            background-color: $white-color;
        }
    }
}
.unique-single-feedback {
    background-color: $white-color;
    box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
    padding: 40px 45px;
    border: 1px solid #F9F9F9;
    border-radius: 5px;
    transition: $transition;
    position: relative;
    z-index: 1;

    .rating {
        padding: 0;
        margin-bottom: 15px;

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
            i {
                font-size: 18px;
                color: #FFB820;
            }
        }
    }
    .client-info {
        margin-top: 30px;
        position: relative;
        padding-left: 70px;

        img {
            width: 55px !important;
            height: 55px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        h3 {
            margin-bottom: 0;
            color: $main-color;
            font: {
                size: 20px;
            }
        }
        span {
            display: block;
            color: $paragraph-color;
            margin-top: 5px;
            font-size: 15px;
        }
    }
    .quote {
        position: absolute;
        right: 45px;
        top: 55px;
        z-index: -1;
    }
    &:hover {
        border: 1px solid $main-color;
    }
    &.with-black-color {
        background-color: #161618;
        border: 1px solid #161618;

        p {
            color: #F4F5F8;
        }
        .client-info {
            h3 {
                color: #F4F5F8;
            }
            span {
                color: #F4F5F8;
            }
        }
        .quote {
            top: unset;
            bottom: 35px;

            i {
                color: $main-color;
                font-size: 45px;
            }
        }
        &:hover {
            border: 1px solid $main-color;
        }
    }
}
.unique-feedback-slides {
    .swiper-pagination {
        position: initial;
        margin-top: 30px;

        .swiper-pagination-bullet {
            width: 20px;
            height: 10px; 
            background: #E1E1FA; 
            transition: $transition;
            border-radius: 10px; 
            opacity: 1;

            &.swiper-pagination-bullet-active, &:hover {
                background-color: $main-color;
                width: 30px;
            }
        }
    }
}
.unique-feedback-line-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

/*================================================
Mobile App Area CSS
=================================================*/
.mobile-app-area {
    background-color: #F4FAFD;
}
.mobile-app-content {
    padding-left: 90px;

    h3 {
        font-size: 40px;
        margin-bottom: 12px;
        line-height: 1.4;

        span {
            background: linear-gradient(89.21deg, #2F66B9 21.64%, #8659EB 71.02%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
    p {
        margin-bottom: 0;
    }
    .app-btn-box {
        margin-top: 25px;

        .playstore-btn {
            position: relative;
            border-radius: 5px;
            margin-right: 15px;
            display: inline-block;
            color: $paragraph-color;
            background-color: $white-color;
            box-shadow: 0 7px 18px 0 rgba(107, 124, 147, .15);
            font: {
                size: 14px;
                weight: 500;
            };
            padding: {
                top: 12px;
                left: 70px;
                right: 25px;
                bottom: 12px;
            };
            span {
                display: block;
                margin-top: 5px;
                color: $black-color;
                transition: $transition;
                font: {
                    size: 17.5px;
                    weight: 700;
                };
            }
            img {
                transform: translateY(-50%);
                position: absolute;
                left: 20px;
                top: 50%;
            }
            &:hover {
                background-color: $main-color;
                transform: translateY(-3px);
                color: $white-color;

                span {
                    color: $white-color;
                }
            }
        }
        .applestore-btn {
            position: relative;
            border-radius: 5px;
            display: inline-block;
            color: $paragraph-color;
            background-color: $white-color;
            box-shadow: 0 7px 18px 0 rgba(107, 124, 147, .15);
            font: {
                size: 14px;
                weight: 500;
            };
            padding: {
                top: 12px;
                left: 68px;
                right: 25px;
                bottom: 12px;
            };
            span {
                display: block;
                margin-top: 5px;
                color: $black-color;
                transition: $transition;
                font: {
                    size: 17.5px;
                    weight: 700;
                };
            }
            img {
                transform: translateY(-50%);
                position: absolute;
                left: 20px;
                top: 50%;
            }
            &:hover {
                background-color: $black-color;
                transform: translateY(-3px);
                color: $white-color;

                span {
                    color: $white-color;
                }
            }
        }
    }

}

/*================================================
Blog Area CSS
=================================================*/
.single-blog-card {
    background: #F4FAFD;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #F4FAFD;
    transition: $transition;
    margin-bottom: 30px;

    .tag {
        position: relative;
        margin-top: -40px;

        span {
            display: inline-block;
            padding: 10px 25px;
            border-radius: 30px;
            background-color: $main-color;
            color: $white-color;
            font-size: 14px;
            font-weight: 500;
        }
    }
    .post-meta {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 18px;
        };
        li {
            display: inline-block;
            list-style-type: none;
            font-size: 15px;
            color: $paragraph-color;
            position: relative;
            margin-right: 20px;
            padding-left: 22px;

            &:last-child {
                margin-right: 0;
            }
            i {
                color: $main-color;
                position: absolute;
                left: 0;
                top: 2px;
                font-size: 15px;
            }
        }
    }
    h3 {
        font-size: 24px;
        line-height: 1.4;
        margin-bottom: 12px;

        a {
            color: $black-color;
        }
    }
    .info {
        margin-top: 20px;
        position: relative;
        padding-left: 70px;

        img {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 18px;
            }
        }
        span {
            display: block;
            color: $paragraph-color;
            margin-top: 10px;
            font-size: 15px;
        }
    }
    &:hover {
        border: 1px solid $main-color;
        transform: translateY(-5px);

        h3 {
            a {
                color: $main-color;
            }
        }
    }
    &.with-black-color {
        border: 1px solid rgba(203, 220, 221, 0.06);
        background-color: transparent;

        .post-meta {
            li {
                color: #F4F5F8;
                
                i {
                    background: linear-gradient(90deg, #D3064E -0.61%, #FE5B67 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
            }
        }
        h3 {
            a {
                color: #F4F5F8;
            }
        }
        p {
            color: #F4F5F8;
        }
        .info {
            h3 {
                color: #F4F5F8;
            }
            span {
                color: #F4F5F8;
            }
        }
        &:hover {
            border: 1px solid #161618;
            background-color: #161618;
            transform: translateY(-5px);
        }
    }
}

/*================================================
Subscribe Wrap Area CSS
=================================================*/
.subscribe-wrap-area {
    position: relative;
    z-index: 1;
    
    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        height: 150px;
        width: 100%;
        background-color: #1F102B;
        z-index: -1;
    }
    &.with-black-color {
        background-color: #1F1F22;

        &::before {
            background-color: #161618;
        }
    }
}
.subscribe-wrap-inner-box {
    background: linear-gradient(86.42deg, #2E66B9 0%, #8859EC 101.05%);
    max-width: 950px;
    margin: auto;
    padding: {
        top: 80px;
        bottom: 80px;
        left: 150px;
        right: 150px;
    };
    border-radius: 5px;
    position: relative;
    z-index: 1;

    .subscribe-content {
        text-align: center;
        
        h2 {
            color: $white-color;
            margin-bottom: 0;
            font-size: 40px;
        }
        .newsletter-form {
            max-width: 550px;
            position: relative;
            margin: {
                left: auto;
                right: auto;
                top: 35px;
            }
            .input-newsletter {
                display: block;
                width: 100%;
                background-color: $white-color;
                border: none;
                height: 65px;
                padding-left: 25px;
                border-radius: 70px;
                padding-top: 5px;
                outline: 0;
                color: $black-color;
    
                &::placeholder {
                    color: $paragraph-color;
                }
            }
            button {
                position: absolute;
                right: 5px;
                top: 5px;
                background-color: $main-color;
                color: $white-color;
                border: none;
                height: 55px;
                padding: 0 30px;
                border-radius: 70px;
                transition: $transition;
                line-height: 60px;
                text-transform: uppercase;
                font: {
                    size: 15px;
                    weight: 700;
                }
                &:hover {
                    background-color: $optional-color;
                    color: $white-color;
                }
            }
            #validator-newsletter {
                position: absolute;
                left: 0;
                margin: 0 auto;
                right: 0;
                color: $white-color;
                bottom: -35px;
            }
        }
    }
    .subscribe-shape-1 {
        position: absolute;
        left: 30px;
        bottom: 30px;
        z-index: -1;
        animation: moveBounce 5s linear infinite;
    }
    .subscribe-shape-2 {
        position: absolute;
        left: 150px;
        top: 50px;
        z-index: -1;
        animation: moveLeftBounce 3s linear infinite;
    }
    .subscribe-shape-3 {
        position: absolute;
        right: 150px;
        top: 50px;
        z-index: -1;
        animation: moveBounce 5s linear infinite;
    }
}

/*================================================
Cyber Security Banner Area CSS
=================================================*/
.cs-banner-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: #161618;
    padding: {
        top: 150px;
        bottom: 150px;
    };
    &::before {
        position: absolute;
        content: "";
        width: 348px;
        height: 100%;
        background-color: #1F1F22;
        left: 0;
        top: 0;
        z-index: -1;
    }
    .container-fluid {
        padding: {
            left: 80px;
            right: 50px;
        };
    }
}
.cs-banner-content {
    .sub-title {
        color: $white-color;
        font-size: $font-size;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 18px;
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            content: "";
            left: 0;
            bottom: 5px;
            background: linear-gradient(90deg, #D3064E -0.61%, #FE5B67 100%);
            height: 5px;
            width: 100%;
            z-index: -1;
        }
    }
    h1 {
        font-size: 90px;
        font-weight: 800;
        margin-bottom: 15px;
        color: $white-color;
    }
    p {
        margin-bottom: 0;
        color: $white-color;
        font-size: 22px;
        font-weight: 500;
    }
    .banner-btn {
        padding: 0;
        margin: {
            top: 30px;
            bottom: 0;
        };
        li {
            display: inline-flex;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
            .video-btn {
                margin-left: 5px;
                font-size: 15.5px;
                font-weight: 600;
                color: $white-color;
                cursor: pointer;

                i {
                    background: rgba(47, 143, 232, 0.07);
                    height: 45px;
                    width: 45px;
                    display: inline-block;
                    color: $main-color;
                    text-align: center;
                    line-height: 45px;
                    border-radius: 50%;
                    padding-left: 5px;
                    margin-right: 8px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color
                    };
                }
            }
        }
    }
}
.cs-banner-shape {
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 30s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.cs-lock {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateY(-10%) translateX(-50%);
    z-index: -1;
    animation: moveBounce 5s linear infinite;
}

/*================================================
Card Area CSS
=================================================*/
.cs-card-area {
    padding: {
        top: 70px;
        bottom: 40px;
    };
}
.cs-card-content {
    margin-bottom: 30px;

    h3 {
        font-size: 20px;
        margin-bottom: 0;
        position: relative;
        padding-top: 22px;
        font-weight: 600;

        a {
            color: $white-color;
        }
        &::before {
            position: absolute;
            content: "";
            width: 200px;
            height: 1px;
            left: 0;
            top: 0;
            background: rgba(47, 143, 232, 0.1);
        }
        &::after {
            position: absolute;
            content: "";
            width: 115px;
            height: 1px;
            left: 0;
            top: 0;
            background: rgba(47, 143, 232, 0.4);
        }
    }
}

/*================================================
Features Area CSS
=================================================*/
.cs-features-area {
    overflow: hidden;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
    }
}
.cs-features-item {
	position: relative;
    z-index: 1;
	padding: 80px 70px;
	overflow: hidden;
	background-size: cover;
	background-position: center center;
    background-repeat: no-repeat;
    transition: $transition;
    
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(31, 31, 34, 0.96) 0%, rgba(31, 31, 34, 0.9) 58.33%, rgba(22, 22, 24, 0.6) 100%);
        transition: $transition;
        z-index: -1;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(80.77deg, #7906D3 0%, #1377D2 100%);
        transition: $transition;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
    }
    .content {
        h3 {
            font-size: 24px;
            margin-bottom: 16.8px;

            a {
                color: $white-color;
            }
        }
        p {
            color:$white-color;
            margin-bottom: 20px;
        }
        .get-started-btn {
            font-size: 15px;
            font-weight: bold;
            color: $white-color;
            position: relative;
            z-index: 1;
            padding-left: 10px;

            &::before {
                position: absolute;
                content: "";
                height: 30px;
                width: 30px;
                background-color: $main-color;
                border-radius: 50px;
                left: 0;
                bottom: -2.5px;
                z-index: -1;
            }
        }
    }
    .bg-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;

        i {
            font-size: 120px;
            color: $white-color;
            opacity: 5%;
        }
    }
    &:hover {
        &::before {
            opacity: 0;
            visibility: hidden;
        }
        &::after {
            opacity: 1;
            visibility: visible;
        }
    }
}

/*================================================
CS Partner Area CSS
=================================================*/
.cs-partner-area {
    .container-fluid {
        padding: {
            left: 50px;
            right: 50px;
        };
    }
}
.cs-partner-item {
    text-align: center;
    margin-bottom: 30px;

    a {
        img {
            display: inline-block;
            max-width: 100%;
            width: auto;
            margin: auto;
        }
    }
}

/*================================================
CS About Area CSS
=================================================*/
.cs-about-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 955px;
        background-color: #161618;
        left: 0;
        top: 0;
        z-index: -1;
    }
}
.cs-about-content {
    padding-left: 35px;

    .sub-title {
        color: $white-color;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        color: $white-color;
        margin-bottom: 15px;

        span {
            position: relative;
            z-index: 1;

            &::before {
                position: absolute;
                content: "";
                left: 0;
                bottom: 12px;
                background: linear-gradient(90deg, #D3064E -0.61%, #FE5B67 100%);
                height: 5px;
                width: 100%;
                z-index: -1;
            }
        }
    }
    p {
        color: #F4F5F8;
        margin-bottom: 0;
    }
    .cs-about-card {
        margin-top: 30px;

        .icon {
            display: inline-block;
            height: 85px;
            width: 85px;
            line-height: 85px;
            background: #161618;
            box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
            color: $white-color;
            font-size: 35px;
            text-align: center;
            margin-bottom: 30px;
            position: relative;
            z-index: 1;

            &::before {
                position: absolute;
                content: "";
                display: inline-block;
                height: 85px;
                width: 85px;
                line-height: 85px;
                left: 0;
                right: 0;
                top: 0;
                background: linear-gradient(80.77deg, #7906D3 0%, #1377D2 100%);
                z-index: -1;
                transition: $transition;
                opacity: 0;
                visibility: hidden;
            }
        }
        h4 {
            color: $white-color;
            font-size: 20px;
            margin-bottom: 0;
            position: relative;
            padding-bottom: 18px;
            margin-bottom: 18px;
            
            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 1px;
                left: 0;
                bottom: 0;
                background: rgba(47, 143, 232, 0.1);
            }
            &::after {
                position: absolute;
                content: "";
                width: 115px;
                height: 1px;
                left: 0;
                bottom: 0;
                background: rgba(47, 143, 232, 0.4);
            }
        }
        &:hover {
            .icon {
                &::before {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .about-btn {
        margin-top: 30px;
    }
}

/*================================================
CS Choose Area CSS
=================================================*/
.cs-choose-content {
    padding-right: 25px;

    .sub-title {
        color: $white-color;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        line-height: 1.4;
        color: $white-color;

        span {
            position: relative;
            z-index: 1;

            &::before {
                position: absolute;
                content: "";
                left: 0;
                bottom: 12px;
                background: linear-gradient(90deg, #D3064E -0.61%, #FE5B67 100%);
                height: 5px;
                width: 100%;
                z-index: -1;
            }
        }
    }
    p {
        margin-bottom: 0;
        color: #F4F5F8;
    }
    .list {
        padding: 0;
        margin: {
            top: 22px;
            bottom: 0;
        };
        li {
            list-style-type: none;
            font-size: 16.5px;
            font-weight: bold;
            color: #F4F5F8;
            margin-bottom: 15px;
            position: relative;
            padding-left: 25px;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                position: absolute;
                left: 0;
                top: 2px;
                color: #76BAAF;
                font-size: 16.5px;
            }
        }
    }
    .choose-btn {
        margin-top: 28px;
    }
}

/*================================================
CS Services Area CSS
=================================================*/
.cs-services-card {
    margin-bottom: 30px;
    background: #161618;
    box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
    padding: 30px;
    border-radius: 5px;
    transition: $transition;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
        position: absolute;
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        background: linear-gradient(80.77deg, #7906D3 0%, #1377D2 100%);
        z-index: -1;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
    }
    .image {
        display: inline-block;
        height: 100px;
        width: 100px;
        line-height: 100px;
        background: #1F1F22;
        box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
        text-align: center;
        border-radius: 50%;
        margin-bottom: 28px;
        transition: $transition;
        
        img {
            max-width: 65px;
        }
    }
    h3 {
        font-size: 22px;
        margin-bottom: 14px;

        a {
            color: $white-color;
        }
    }
    p {
        color: #F4F5F8;
    }
    &:hover{
        transform: translateY(-5px);

        &::before {
            opacity: 1;
            visibility: visible;
        }
    }
}

/*================================================
CS Fun Facts Area CSS
=================================================*/
.cs-fun-facts-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 1150px;
        background-color: #161618;
        right: 0;
        top: 0;
        z-index: -1;
    }
}
.cs-single-fun-facts {
    margin-bottom: 30px;
    padding-bottom: 35px;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        width: 100px;
        height: 3.2px;
        left: 0;
        bottom: 0;
        background: $main-color;
    }
    .number {
        font-size: 14px;
        color: $paragraph-color;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 22px;
    }
    h3 {
        position: relative;
        color: $white-color;
        margin-bottom: 0;
        font-size: 38px;

        .sign-icon {
            display: inline-block;
            font-size: 35px;
            margin-left: 2px;
        }
    }
    p {
        line-height: initial;
        margin-top: 12px;
        color: #F4F5F8;
        font: {
            size: 16.8px;
            weight: 500;
        }
    }
}

/*================================================
Leading Technology Area CSS
=================================================*/
.leading-technology-content {
    padding-left: 30px;

    .sub-title {
        color: $white-color;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        line-height: 1.4;
        color: $white-color;

        span {
            position: relative;
            z-index: 1;

            &::before {
                position: absolute;
                content: "";
                left: 0;
                bottom: 12px;
                background: linear-gradient(90deg, #D3064E -0.61%, #FE5B67 100%);
                height: 5px;
                width: 100%;
                z-index: -1;
            }
        }
    }
    p {
        margin-bottom: 0;
        color: #F4F5F8;
    }
    .list {
        padding: 0;
        margin: {
            top: 22px;
            bottom: 0;
        };
        li {
            border-radius: 5px;
            list-style-type: none;
            font-size: 15.5px;
            font-weight: 600;
            color: #F4F5F8;
            margin-bottom: 15px;
            position: relative;
            border: 1px solid rgba(203, 220, 221, 0.06);
            padding: 10px 10px 10px 38px;
            transition: $transition;

            &:last-child {
                margin-bottom: 0;
            }
            &::before {
                position: absolute;
                content: "";
                height: 10px;
                width: 10px;
                background: linear-gradient(80.77deg, #7906D3 0%, #1377D2 100%);
                border-radius: 50px;
                left: 15px;
                top: 14.5px;
            }
            &:hover {
                border: 1px solid $optional-color;
                transform: translateY(-2px);
            }
        }
    }
}

/*================================================
CS Overview Area CSS
=================================================*/
.cs-overview-item {
    margin-bottom: 30px;
    padding: 30px;
    border: 1px solid rgba(203, 220, 221, 0.06);
    border-radius: 5px;
    transition: $transition;

    .cs-overview-content {
        h3 {
            font-size: 22px;
            margin-bottom: 20px;
            color: $white-color;
            line-height: 1.4;
        }
        .get-started-btn {
            font-size: 15px;
            font-weight: 500;
            color: $white-color;
            position: relative;
            z-index: 1;
            padding-left: 10px;

            &::before {
                position: absolute;
                content: "";
                height: 25px;
                width: 25px;
                background-color: $main-color;
                border-radius: 50px;
                left: 0;
                bottom: 0.5px;
                z-index: -1;
            }
        }
    }
    &:hover {
        border: 1px solid $main-color;
        transform: translateY(-5px);
    }
}

/*================================================
Big Data Banner Area CSS
=================================================*/
.bd-banner-section {
    background-color: #F1F9F9;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: {
        top: 50px;
        bottom: 50px;
    };
    .container-fluid {
        padding: {
            left: 50px;
            right: 50px;
        };
    }
}
.bd-banner-content {
    max-width: 650px;

    .sub-title {
        background-color: $white-color;
        box-shadow: -2px 6px 15px rgba(67, 127, 236, 0.07);
        color: $paragraph-color;
        font-size: $font-size;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 25px;
        border-radius: 5px;
        padding: 8px 15px;
    }
    h1 {
        font-size: 55px;
        font-weight: 800;
        margin-bottom: 15px;

        span {
            position: relative;
            z-index: 1;

            &::before {
                position: absolute;
                content: "";
                left: 0;
                bottom: 12px;
                background: linear-gradient(90deg, #D3064E -0.61%, #FE5B67 100%);
                height: 5px;
                width: 100%;
                z-index: -1;
            }
        }
    }
    p {
        margin-bottom: 0;
    }
    .banner-btn {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 0;
        };
        li {
            display: inline-flex;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
            .video-btn {
                margin-left: 5px;
                font-size: 15.5px;
                font-weight: 600;
                color: $black-color;
                cursor: pointer;

                i {
                    background: rgba(47, 143, 232, 0.07);
                    height: 45px;
                    width: 45px;
                    display: inline-block;
                    color: $main-color;
                    text-align: center;
                    line-height: 45px;
                    border-radius: 50%;
                    padding-left: 5px;
                    margin-right: 8px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color
                    };
                }
            }
        }
    }
}
.bd-banner-image {
    position: relative;
    z-index: 1;
    text-align: center;

    .circle-shape {
        width: 865px;
        height: 650px;
        border-radius: 50%;
        background-color: $white-color;
        z-index: -1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -150px;
    }
}
.bd-banner-shape-1 {
    position: absolute;
    left: 50px;
    top: 50px;
    z-index: -1;
    animation: moveBounce 5s linear infinite;
}
.bd-banner-shape-2 {
    position: absolute;
    top: 5%;
    left: 45%;
    transform: translateY(-5%) translateX(-45%);
    z-index: -1;
    animation: moveBounce 5s linear infinite;
}
.bd-banner-shape-3 {
    position: absolute;
    bottom: 10%;
    left: 30%;
    transform: translateY(-10%) translateX(-30%);
    z-index: -1;
    animation: moveBounce 5s linear infinite;
}

/*================================================
BD Fun Facts Area CSS
=================================================*/
.bd-fun-facts-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
    }
    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 70px;
        background: #F1F9F9;
        z-index: -1;
    }
}
.bd-fun-facts-inner-box {
    background: linear-gradient(80.77deg, #7906D3 0%, #1377D2 100%);
    border-radius: 0px 20px 20px 0px;
    max-width: 1320px;
    padding: {
        left: 50px;
        right: 50px;
    };
}
.bd-single-fun-facts {
    margin-bottom: 30px;
    padding-bottom: 28px;
    position: relative;
    padding-left: 100px;

    &::before {
        position: absolute;
        content: "";
        width: 100px;
        height: 3.2px;
        bottom: 0;
        background: #FBBDE7;
    }
    .image-icon {
        display: inline-block;
        height: 80px;
        width: 80px;
        line-height: 80px;
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.09);
        text-align: center;
        border-radius: 50%;
        margin-bottom: 28px;
        transition: $transition;
        position: absolute;
        left: 0;
        top: 0;

        img {
            max-width: 45px;
        }
    }
    h3 {
        position: relative;
        color: $white-color;
        margin-bottom: 0;
        font-size: 38px;

        .sign-icon {
            display: inline-block;
            font-size: 35px;
            margin-left: 2px;
        }
    }
    p {
        line-height: initial;
        margin-top: 10px;
        color: #F4F5F8;
        font: {
            size: 16.8px;
            weight: 500;
        }
    }
    span.odometer-formatting-mark {
        display: none;
    }
}

/*================================================
BD Featured Area CSS
=================================================*/
.bd-featured-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.bd-featured-card {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
    padding: 30px;
    border-radius: 5px;
    transition: $transition;

    .featured-image {
        display: inline-block;
        height: 100px;
        width: 100px;
        line-height: 100px;
        background-color: $white-color;
        text-align: center;
        border-radius: 50px;
        box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.1);
        margin-bottom: 28px;
        transition: $transition;

        img {
            max-width: 45px;
        }
    }
    h3 {
        font-size: 22px;
        margin-bottom: 14px;
    }
    &:hover{
        transform: translateY(-5px);
    }
}
.bd-featured-shape {
    position: absolute;
    top: 5%;
    right: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;
    animation: moveBounce 5s linear infinite;
}

/*================================================
BD About Area CSS
=================================================*/
.bd-about-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.bd-about-content {
    padding-left: 35px;

    .sub-title {
        color: $main-color;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;

        span {
            position: relative;
            z-index: 1;

            &::before {
                position: absolute;
                content: "";
                left: 0;
                bottom: 12px;
                background: linear-gradient(90deg, #D3064E -0.61%, #FE5B67 100%);
                height: 5px;
                width: 100%;
                z-index: -1;
            }
        }
    }
    p {
        margin-bottom: 0;
    }
    .inner-content-card {
        border-radius: 5px;
        background-color: $white-color;
        box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.07);
        margin-top: 30px;
        position: relative;
        padding: 30px 30px 30px 138px;
        transition: $transition;

        .icon-image {
            display: inline-block;
            height: 85px;
            width: 85px;
            line-height: 85px;
            background-color: $white-color;
            text-align: center;
            border-radius: 50px;
            box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.1);
            transition: $transition;
            position: absolute;
            left: 30px;
            top: 30px;
        }
        h4 {
            font-size: 22px;
            margin-bottom: 12.8px;
        }
        &:hover {
            transform: translateY(-5px);
        }
    }
}
.bd-about-shape {
    position: absolute;
    top: 5%;
    right: 5%;
    transform: translateY(-5%) translateX(-5%);
    z-index: -1;
    animation: moveBounce 5s linear infinite;
}

/*================================================
BD Services Area CSS
=================================================*/
.bd-services-area {
    background-color: #F4FAFD;
}
.bd-services-card {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
    padding: 30px;
    border-radius: 5px;
    transition: $transition;

    .services-image {
        display: inline-block;
        height: 90px;
        width: 90px;
        line-height: 90px;
        background-color: $white-color;
        text-align: center;
        border-radius: 50px;
        box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.1);
        margin-bottom: 28px;
        transition: $transition;

        img {
            max-width: 45px;
        }
    }
    h3 {
        font-size: 24px;
        margin-bottom: 14px;
    }
    p {
        margin-bottom: 20px;
    }
    .get-started-btn {
        font-size: 15px;
        font-weight: bold;
        color: $paragraph-color;
        position: relative;
        z-index: 1;
        padding-left: 10px;

        &::before {
            position: absolute;
            content: "";
            height: 25px;
            width: 25px;
            background-color: #FBB8A9;
            border-radius: 50px;
            left: 0;
            bottom: 0.5px;
            z-index: -1;
            transition: $transition;
        }
    }
    &:hover{
        transform: translateY(-5px);

        .get-started-btn {
            color: $main-color;

            &::before {
                background-color: $optional-color;
            }
        }
    }
}

/*================================================
BD Choose Area CSS
=================================================*/
.bd-choose-content {
    padding-right: 25px;

    .sub-title {
        color: $main-color;
        font-size: 15px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        line-height: 1.4;

        span {
            position: relative;
            z-index: 1;

            &::before {
                position: absolute;
                content: "";
                left: 0;
                bottom: 12px;
                background: linear-gradient(90deg, #D3064E -0.61%, #FE5B67 100%);
                height: 5px;
                width: 100%;
                z-index: -1;
            }
        }
    }
    p {
        margin-bottom: 0;
    }
    .list {
        padding: 0;
        margin: {
            top: 22px;
            bottom: 0;
        };
        li {
            list-style-type: none;
            font-size: 16.5px;
            font-weight: bold;
            color: $paragraph-color;
            margin-bottom: 15px;
            position: relative;
            padding-left: 25px;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                position: absolute;
                left: 0;
                top: 2px;
                color: #76BAAF;
                font-size: 16.5px;
            }
        }
    }
    .choose-btn {
        margin-top: 28px;
    }
}

/*================================================
BD Choose Area CSS
=================================================*/
.bd-overview-area {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.bd-overview-content {
    max-width: 650px;
    margin-left: auto;
}
.bd-overview-card {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0px 6px 20px rgba(26, 137, 185, 0.07);
    padding: 30px;
    border-radius: 5px;
    transition: $transition;

    .overview-image {
        display: inline-block;
        height: 90px;
        width: 90px;
        line-height: 90px;
        background-color: $white-color;
        text-align: center;
        border-radius: 50px;
        box-shadow: 0px 4px 20px rgba(26, 137, 185, 0.1);
        margin-bottom: 28px;
        transition: $transition;

        img {
            max-width: 45px;
        }
    }
    h3 {
        font-size: 24px;
        margin-bottom: 14px;
    }
    p {
        margin-bottom: 20px;
    }
    &:hover {
        transform: translateY(-5px);
    }
}

/*================================================
Footer Wrap Area CSS
=================================================*/
.footer-wrap-area {
    background-color: #1F102B;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.with-black-color {
        background-color: #161618;
    }
}
.single-footer-card {
    margin-bottom: 30px;

    .logo {
        display: inline-block;
        margin-bottom: 25px;
    }
    p {
        color: $white-color;
    }
    h3 {
        margin-bottom: 25px;
        font-size: 22px;
        color: $white-color;
    }
    .social-links {
        padding: 0;
        list-style-type: none;
        margin: {
            top: 18px;
            bottom: 0;
        };
        li {
            display: inline-block;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
            a {
                i {
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background: $white-color;
                    color: $paragraph-color;
                    border-radius: 50%;
                    font-size: $font-size;
                    text-align: center;
                    transition: $transition;
                    
                    &:hover {
                        color: $white-color;
                        background-color: $main-color;
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }
    .footer-quick-links {
        padding: 0;
        margin-bottom: 0;

        li {
            list-style-type: none;
            margin-bottom: 15.5px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $white-color;
                position: relative;
                padding-left: 22px;
                font-size: $font-size;
                font-weight: 400;

                i {
                   color: $optional-color;
                   font-size: 15.5px;
                   position: absolute;
                   left: 0;
                   top: 3.5px;
                }
                &:hover {
                    color: $optional-color;
                }
            }
        }
    }
    .contact-links {
        padding: 0;
        margin-bottom: 0;

        li {
            color: $white-color;
            list-style-type: none;
            margin-bottom: 18.5px;
            font-weight: 400;

            &:last-child {
                margin-bottom: 0;
            }
            span {
                font-weight: bold;
            }
            a {
                color: $white-color;

                &:hover {
                    color: $optional-color;
                }
            }
        }
    }
}
.footer-wrap-line {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.footer-wrap-shape {
    position: absolute;
    top: 10%;
    right: 5%;
    transform: translateY(-10%) translateX(-5%);
    z-index: -1;

    img {
        animation-name: rotateMe;
        animation-duration: 30s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}
.footer-wrap-shape-2 {
    position: absolute;
    bottom: 10%;
    left: 2%;
    transform: translateY(-10%) translateX(-2%);
    animation: moveBounce 5s linear infinite;
    z-index: -1;
}

/*================================================
Copyright Wrap Area CSS
=================================================*/
.copyright-wrap-area {
    background-color: #11011E;
    padding: {
        top: 25px;
        bottom: 25px;
    }
    p {
        color: $white-color;
        margin-bottom: 0;

        a {
            display: inline-block;
            color: $optional-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .list {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        text-align: right;

        li {
            display: inline-block;
            margin: 0 10px;
            color: $white-color;
            position: relative;

            a {
                display: inline-block;
                color: $white-color;

                &:hover {
                    color: $optional-color;
                }
            }
            &::before {
                content: '';
                position: absolute;
                right: -12px;
                top: 4px;
                width: 1px;
                height: 14px;
                background-color: $white-color;
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
    &.with-black-color {
        background-color: #09090A;
    }
}

/* End "Cryptocurrency Demo CSS, "Cyber Security Demo CSS & Big Data Solution Demo CSS" */